/**
 * This file contains functions to process and duplicate repeatable CMS components within a card or story.
 * The main function, processRepeatableComponents, handles the duplication and recursive processing of child
 * components based on their CMS references and context.
 */

import {
	StoryVersionType,
	BBModel,
	BBSymbolLink,
	BBStylesProp,
	BBOtherProp,
	StorySymbols,
	StoryStep,
} from 'types/story';
import type { CmsCollectionDataItem, CmsModel } from 'types/cms';
import produce, { Draft } from 'immer';
import { set } from 'lodash';
import cms from 'common/utils/cms';
import PaginationManager from 'common/utils/cms/pagination-manager';
import { appLog, deepClone } from 'common/utils/helpers';
import { updateId } from 'common/utils/generate-id';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalk } from 'common/utils/blocks/component-walk';
import type { CmsChangePageEvent } from 'common/utils/cms/cms-event-emitter';
import { getSymbolModel } from 'common/utils/blocks/get-symbol-model';
import { createIdsMap, updateReferenceIds } from 'common/utils/blocks/update-reference-ids';
import { findComponentBy } from 'common/utils/blocks/find-component-by';
import type { GenerateIdCallback } from 'common/utils/blocks/symbol-manager';
import { isSymbolLink, isSymbol, getInstanceByRef, isSymbolChild } from 'common/utils/blocks/symbol';

// Test cases:
//   === Plain component ===
// - ✅Check single repeatable element
// - ✅Check repeatable with a children
// - ⚪️Check repeatable inside repeatable, not supported yet
// - ✅With nested linked Overlay
//   === Symbol component ===
// - ✅Check if the master symbol is repeatable
// - ✅Check if the instance symbol is repeatable
// - ✅Check if symbol is child of repeatable
// - ✅Check if the symbol child is repeatable. Done: repeat is ignored.
// - ✅With nested linked Overlay

const log = appLog.extend('CmsRepeatableComponentsProcessor');

type DraftElement = Draft<BBModel>;

type RepeatableContext = { data: CmsCollectionDataItem; collectionId: string };

type Items = CmsModel['items'];

type ParamsType<T extends BBModel[] | StoryVersionType> = T extends BBModel[]
	? { type: 'update-item'; data: T; symbols: StorySymbols; items: Items } & CmsChangePageEvent['data']
	: { type: 'update-story'; data: T; symbols: StorySymbols; items: Items };

/**
 * Process repeatable components in a card or a story and return a new card or story with duplicated components
 * fulfilled with proper references to the CMS data.
 */
export function processRepeatableComponents<T extends BBModel[] | StoryVersionType>(
	params: ParamsType<T>
): { data: T; isModified: boolean } {
	const { items: collectionItems, symbols = {} } = params;
	const duplicatedElementsIds = new Set<string>();
	const duplicatedElementsCtx = new Map<string, RepeatableContext>();
	const newIds = createIdsMap();
	const collectNewIds: GenerateIdCallback = ({ oldId, ...data }) => newIds.set(oldId, data);
	let isModified = false;

	const result = produce(params, draft => {
		if (draft.type === 'update-story') processSteps(draft.data.data.steps);
		else if (draft.type === 'update-item') processElements(draft.data);

		duplicatedElementsCtx.clear();
		duplicatedElementsIds.clear();
	});

	return {
		data: result.data as T,
		isModified,
	};

	function processElements(elements: BBModel[]) {
		return elements.forEach(element => processElement(element));
	}

	function processSteps(steps: StoryStep[]) {
		return steps.forEach(step => step.cards.forEach(card => processElements(card.elements)));
	}

	/**
	 * Processes a draft element by handling its CMS references and duplicating repeatable child components.
	 * It recursively processes each child element.
	 *
	 * Detailed Functionality:
	 *
	 * * Context Handling
	 *   If a context is provided, it modifies text nodes, styles, and uniform properties based on the context.
	 *
	 * * Child Processing:
	 *   If the draft element has children, it iterates over them. If a child is a repeatable component
	 *   (determined by the presence of a collectionId), it duplicates the child and processes the duplicates.
	 *
	 * * Duplication Logic:
	 *   Uses a while loop to handle the addition of new elements to the iterable array. It duplicates the child
	 *   components, generates new IDs, updates references, and replaces the original child with the duplicates.
	 *
	 * * Recursive Processing:
	 *   Recursively processes each child element, passing the context if available
	 */
	function processElement(draftElement: DraftElement, context?: RepeatableContext) {
		// replace references based on context
		if (context) {
			// handle text cms references
			modifyTextNodes({ draftElement, context, symbols });

			// handle styles, other cms references
			modifyStyleOrOtherProps({ draftElement, context, symbols });

			// handle uniform props cms references
			modifyUniformProps({ draftElement, context, symbols });
		}

		if (Array.isArray(draftElement.children)) {
			let childIndex = 0;

			/*
			 This code duplicates repeatable child components and recursively processes them.
			 If a child has a collectionId, it's a repeatable component and should be duplicated.
			 It's important to use a `while` loop because we're adding new elements to the iterable array.
			 */
			while (childIndex < draftElement.children.length) {
				const rawChild = draftElement.children[childIndex];
				const child = isSymbolLink(rawChild) ? getSymbolModel(rawChild, symbols) : rawChild;
				const { collectionId } = child.uiConfig.componentProps;
				const collectionData = collectionId ? collectionItems?.[collectionId] : null;
				const isChildOfRepeatable = context !== undefined;
				const isDuplicate = duplicatedElementsIds.has(child._id);
				const shouldDuplicate =
					collectionId &&
					!!collectionData?.length &&
					!isChildOfRepeatable &&
					!isDuplicate &&
					!isSymbolChild(child);

				if (shouldDuplicate) {
					log('duplicate:start', { _id: child._id, type: child.type, collectionId });
					const duplicatedElements: (BBModel | BBSymbolLink)[] = [];

					const { dataSlice, deleteCount, pagination } = PaginationManager.handlePagination({
						element: child,
						collectionId,
						collectionData,
						symbols,
						// Pagination particular component data
						page: params.type === 'update-item' ? params.page : undefined,
						baseComponentId: params.type === 'update-item' ? params.baseComponentId : undefined,
					});

					const sliceSize = dataSlice.length;
					if (sliceSize) {
						for (let j = 0; j < sliceSize; j += 1) {
							let newElement: BBModel = deepClone(pagination.originalElement);

							// generate new id for each component
							componentWalk([newElement], function walk({ component }) {
								const oldId = component._id;
								updateId(component);
								collectNewIds({ oldId, newId: component._id, type: component.type });
								componentWalk(component.children, walk);
							});

							[newElement] = updateReferenceIds([newElement], newIds, symbols);
							duplicatedElementsIds.add(newElement._id);
							duplicatedElements.push(newElement);

							// collect data for each duplicated element
							duplicatedElementsCtx.set(newElement._id, { data: dataSlice[j], collectionId });

							// store link with original element
							PaginationManager.originMap.set(newElement._id, pagination.originalElement._id);
						}

						if (pagination?.strategy === 'page') {
							// replace current and all duplicates with new elements
							draftElement.children.splice(
								childIndex,
								deleteCount,
								// @ts-expect-error fixme
								...duplicatedElements
							);
						} else if (pagination?.strategy === 'infinite') {
							// todo: implement infinite scroll
						}

						isModified = true;

						// Since we've replaced elements including current one, we need to loop again with it
						// without incrementing the index
						// eslint-disable-next-line no-continue
						continue;
					}
				}

				// process children
				processElement(child, context ?? duplicatedElementsCtx.get(child._id));
				childIndex += 1;
			}
		}
	}
}

type ApplyModificationParams = { draftElement: Draft<BBModel>; path: string; value: any; symbols: StorySymbols };

// Applies modifications to a draft element based on the provided path and value.
function applyModification(params: ApplyModificationParams) {
	// log('applyModification', { params });
	if (isSymbol(params.draftElement)) {
		const instance = getInstanceByRef(params.draftElement.symbol, params.symbols);
		if (!instance) return;
		// find is because of draftElement might be a symbol child
		const target = findComponentBy([instance], { path: '_id', value: params.draftElement._id });
		if (!target?.component) return;
		set(target.component, params.path, params.value);
	} else {
		set(params.draftElement, params.path, params.value);
	}
}

type ModifyFn = (params: { draftElement: Draft<BBModel>; context: RepeatableContext; symbols: StorySymbols }) => void;

// Modifies text nodes within a draft element based on the provided context.
const modifyTextNodes: ModifyFn = ({ draftElement, context, symbols }) => {
	if (!Array.isArray(draftElement.children)) {
		Object.keys(draftElement.children).forEach(state => {
			Object.keys(draftElement.children[state]).forEach(platform => {
				const originalValue = draftElement.children[state][platform];
				const ref = cms.parseReference(originalValue, false);

				if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
					// replace "any" dataId with an actual dataId
					applyModification({
						path: `children.${state}.${platform}`,
						value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
						draftElement,
						symbols,
					});
				}
			});
		});
	}
};

// Modifies style or other properties within a draft element based on the provided context.
const modifyStyleOrOtherProps: ModifyFn = ({ draftElement, context, symbols }) => {
	const draftObjEntries = [
		['styles', draftElement.uiConfig.componentProps.styles],
		['other', draftElement.uiConfig.componentProps.other],
	] as const;

	draftObjEntries.forEach(([draftObjKey, draftObj]) => {
		Object.entries(draftObj).forEach(([state, platforms]) => {
			Object.entries(platforms).forEach(([platform, entries]) => {
				Object.entries(entries as BBStylesProp | BBOtherProp).forEach(([key, value]) => {
					const ref = cms.parseReference(value, false);

					if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
						// Replace "any" dataId with an actual dataId
						applyModification({
							path: `uiConfig.componentProps.${draftObjKey}.${state}.${platform}.${key}`,
							value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
							draftElement,
							symbols,
						});
					}
				});
			});
		});
	});
};

// Modifies uniform properties within a draft element based on the provided context.
const modifyUniformProps: ModifyFn = ({ draftElement, context, symbols }) => {
	const draftComponentProps = draftElement.uiConfig.componentProps;
	Object.keys(draftComponentProps).forEach(prop => {
		if (prop in UNIFORM_PROPS) {
			const originalValue = draftComponentProps[prop];
			const ref = cms.parseReference(originalValue, false);
			if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
				// replace "any" dataId with an actual dataId
				applyModification({
					path: `uiConfig.componentProps.${prop}`,
					value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
					draftElement,
					symbols,
				});
			}
		}
	});
};
