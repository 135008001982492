import type { GetVideosUsageParams, GetVideosUsageResult } from 'src/types';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { QUERY_KEYS } from '../constants';

const getVideoUsage = async ({ organizationId, ...queryParams }: GetVideosUsageParams) => {
	const query = {
		from: queryParams.from ?? dayjs('1970-01-01').format('YYYY-MM-DD'),
		to: queryParams.to ?? dayjs().add(1, 'day').format('YYYY-MM-DD'),
	};

	const response: ApiV2Response<GetVideosUsageResult> = await videos.getUsage.params({ organizationId }).send(query);

	return response.body;
};

export const useVideosUsage = (params: Partial<Omit<GetVideosUsageParams, 'organizationId'>>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	return useQuery({
		queryKey: [QUERY_KEYS.VIDEOS_USAGE, organizationId],
		queryFn: () => getVideoUsage({ organizationId, ...params }),
	});
};
