import React from 'react';
import type { BBCommonProps } from 'types/story';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { CardTransitionContext } from 'client/components/common/CardTransition';
import type { TransitionHandlerContextType } from 'client/components/common/CardTransition/TransitionHandler';

import { withExpose } from './WithExpose';

function withCardTransitionContext<P>(Component: React.ComponentType<P & TransitionHandlerContextType>) {
	type WithCardTransitionProps = Pick<
		BBCommonProps,
		| 'children'
		| 'currentMediaQuery'
		| 'editorMode'
		| 'getComputedComponentProps'
		| 'isCardTree'
		| 'mediaQuery'
		| 'onTransitionEnd'
		| 'symbol'
		| 'type'
		| 'uiConfig'
		| '_id'
		| 'editableModeProps'
	>;

	const WrappedComponent = withExpose(Component);

	function WithCardTransitionContext(props: Omit<P & WithCardTransitionProps, keyof TransitionHandlerContextType>) {
		if (isLayerType(props).global && props.isCardTree) {
			/*
			 Global elements are rendered by StoryElements component where isCardTree is false.
			 In card tree stored only references to global elements. We should not to render them.
			 References are stored there to help to manage theirs z-index related to card.
			 */
			return null;
		}

		return (
			<>
				<CardTransitionContext.Consumer>
					{context => (
						<WrappedComponent in={context.in} onTransitionEnd={context.onTransitionEnd!} {...props} />
					)}
				</CardTransitionContext.Consumer>
			</>
		);
	}

	WithCardTransitionContext.displayName = `WithCardTransitionContext(${Component.displayName || Component.name})`;

	return WithCardTransitionContext;
}

export { withCardTransitionContext };
