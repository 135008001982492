import { get, reduce, findIndex, map, pick, has, find, slice } from 'lodash';
import { StoryHistory } from 'client/utils';
import type {
	CardData,
	CardDataWithStepInfo,
	StoryModel,
	StoryStep,
	StorySymbols,
	StoryVersions,
	StoryVersionType,
} from 'types/story';
import { STORY_TYPE, STORY_VERSIONS, CountType } from 'common/constants';
import { getAssetURL, replaceUrlHostname } from 'utils/assets';

export type RequestMainRowData = {
	storyId: StoryModel['id'];
	name: StoryModel['name'];
	organizationId: StoryModel['organizationId'];
	type: StoryModel['type'];
	language: StoryModel['language'];
	tags: StoryModel['tags'];
	teamId?: StoryModel['teamId'];
};

type FindCardInStepsResult = { card?: CardData & { index: number }; step?: StoryStep & { index: number } };

/**
 * Pick data from story object that needed to update story
 * @param data
 */
export function requestMainRowData(
	data: Pick<StoryModel, 'id' | 'name' | 'organizationId' | 'type' | 'language' | 'tags' | 'teamId'>
): RequestMainRowData {
	return {
		storyId: data.id,
		name: data.name,
		organizationId: data.organizationId,
		type: data.type,
		language: data.language,
		tags: data.tags,
		teamId: data.teamId || undefined,
	};
}

/**
 * @param name
 * @param storyId
 * @param teamId
 * @param organizationId
 * @param storyData
 * @constructor
 */
export class StoryFacade {
	static VERSIONS = STORY_VERSIONS;

	static getVersionTimestamp(version: StoryVersionType) {
		if (version.version === StoryFacade.VERSIONS.latest || version.version === StoryFacade.VERSIONS.published) {
			return +new Date(version.updatedAt);
		}

		return +version.version.split('_').slice(-1)[0];
	}

	static getSortedVersions(versions: StoryVersions) {
		return Object.values(versions).sort(
			(a, b) => StoryFacade.getVersionTimestamp(b) - StoryFacade.getVersionTimestamp(a)
		);
	}

	static findCardInSteps(cardId: string, steps: StoryStep[]): FindCardInStepsResult {
		const result: FindCardInStepsResult = {};

		steps.some((step, stepIndex) => {
			return (step.cards ?? []).some((card, cardIndex) => {
				const isMatch = card._id === cardId;
				if (isMatch) {
					result.card = { ...card, index: cardIndex };
					result.step = { ...step, index: stepIndex };
				}
				return isMatch;
			});
		});

		return result;
	}

	base: StoryModel;

	// todo: story can be undefined. need change a type and do a big refactor
	story: StoryVersionType;

	constructor(story: StoryModel, defaultVersion: string = StoryFacade.VERSIONS.latest) {
		this.base = story;
		this.story = this.getVersion(defaultVersion);
	}

	getVersion(version: string): StoryVersionType {
		return get(this.base, ['storyVersions', version]);
	}

	setVersion(version: string) {
		this.story = this.getVersion(version);
		return this;
	}

	get isCardEditorAutoSyncEnabled() {
		return this.data.cardEditorAutoSync ?? true;
	}

	/**
	 * Provide data in the format required for an update request
	 * @return {{name: *, storyData: *, storyId: *}}
	 */
	get requestData() {
		return {
			storyId: this.storyId,
			storyData: this.data,
			settings: this.settings,
		};
	}

	get requestMainRowData() {
		return requestMainRowData(this.base);
	}

	get updatedAt() {
		return this.story.updatedAt;
	}

	get language() {
		return this.base.language;
	}

	get settings() {
		return this.story.settings || {};
	}

	get name() {
		return this.base.name;
	}

	get type() {
		return this.base.type ?? STORY_TYPE.STANDALONE;
	}

	get isPublished() {
		return this.story.version === StoryFacade.VERSIONS.published || this.base.status === 'published';
	}

	get data() {
		return this.story.data || {};
	}

	get steps(): StoryVersionType['data']['steps'] {
		return this.data.steps || [];
	}

	get elements(): StoryVersionType['data']['elements'] {
		return this.data.elements || [];
	}

	get mediaQuery(): StoryVersionType['data']['mediaQuery'] {
		return this.data.mediaQuery;
	}

	get storyId() {
		return this.base.id;
	}

	get symbols(): StorySymbols {
		return this.data.symbols || {};
	}

	get storyScore() {
		const sHistory = new StoryHistory({ storyId: this.storyId });
		return {
			[CountType.score]: () => sHistory.totalScore(this.settings, this.cards),
			[CountType.character]: () => sHistory.totalCharacterPoints(this.settings),
		};
	}

	get submittedFormData() {
		return new StoryHistory({ storyId: this.storyId }).submittedFormData;
	}

	get totalVisitedCards() {
		return new StoryHistory({ storyId: this.storyId }).cardsPlayed.length;
	}

	/**
	 * Get cards array
	 * @return {Array}
	 */
	get cards(): CardData[] {
		return reduce<StoryStep, CardData[]>(
			this.steps,
			(result, step) => {
				return result.concat(step.cards);
			},
			[]
		);
	}

	/**
	 * Get cards array
	 * @return {Array}
	 */
	get cardsWithStepInfo(): CardDataWithStepInfo[] {
		return reduce<StoryStep, (CardData & { step: { _id: StoryStep['_id'] } })[]>(
			this.steps,
			(result, step) => {
				const cardsWithStep = map(step.cards, card => ({
					...card,
					step: { ...pick(step, ['_id']) },
				}));

				return result.concat(cardsWithStep);
			},
			[]
		);
	}

	get dataByFirstCard() {
		const result: { card: CardData | null; step: StoryStep | null } = { card: null, step: null };
		const { steps } = this;

		steps.some(step => {
			return (step.cards || []).some(card => {
				const isMatch = has(card, '_id');
				if (isMatch) {
					result.card = card;
					result.step = step;
				}
				return isMatch;
			});
		});
		return result;
	}

	getCardScreenshot = (
		cardId: string,
		version: ValuesType<typeof StoryFacade.VERSIONS> = StoryFacade.VERSIONS.published
	) => {
		const { defaultPlatform } = this.mediaQuery;
		const asset = this.base.storyVersions[version]?.settings.screenshot?.[cardId]?.[defaultPlatform];
		const assetURL = getAssetURL(asset);

		return {
			asset,
			url: replaceUrlHostname(assetURL, this.base.storycardsDomain),
		};
	};

	canUseThirdPartyCookies() {
		const isSubdomainConnected = this.base.domainId !== null;
		const isEmbed = this.base.type === 'embed';
		return (isEmbed && isSubdomainConnected) || !isEmbed;
	}

	getDataByCardId(cardId: string): FindCardInStepsResult {
		return StoryFacade.findCardInSteps(cardId, this.steps);
	}

	getNextDefaultCard(step: StoryStep): CardData | undefined {
		const stepIndex = findIndex(this.steps, ['_id', step._id]);

		if (stepIndex < 0) {
			return undefined;
		}

		const nextStepWithCards = find(slice(this.steps, stepIndex + 1), s => s.cards.length > 0);

		return nextStepWithCards?.cards[0];
	}

	// get text with a replaced supported dynamic tet variables
	getTransformedDynamicText(text: string = '') {
		const scoreResults = this.storyScore[CountType.score]();

		return text
			.replace('$score', `${Math.ceil(scoreResults.scoreRelative) ?? '0'}/100`)
			.replace('$answers', `${scoreResults.cardsScored ?? '0'}`)
			.replace('$correct', `${scoreResults.answersCorrect ?? '0'}`);
	}
}
