/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import * as React from 'react';
import cn from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { NavLink } from 'admin/components/common/NavLink';
import Text from 'admin/components/common/Text';
import FullScreenMenu from 'admin/components/common/FullScreenMenu';
import t from 'utils/translate';
import * as routes from 'admin/constants/routes';
import css from './Navigation.scss';

type Link = {
	name: string;
	disabled?: boolean;
	path: string;
	type: 'internal' | 'external';
};

type Props = {
	links: {
		[key: string]: Link;
	};
	className?: string;
};

class Navigation extends React.PureComponent<Props & RouteComponentProps> {
	static defaultProps: Partial<Props> = {
		className: '',
	};

	get menuLabel() {
		const { match, links } = this.props;
		const defaultLabel = match.url;
		const link = _.find(links, o => o.type === 'internal' && o.path === match.url);
		const label = _.get(link, 'name', defaultLabel);

		return (
			<Text size={Text.size.paragraph} className={css.navLabel}>
				{'Menu' || label}
			</Text>
		);
	}

	renderMenu() {
		const items = _.map(this.props.links, link => ({
			name: (
				<NavLink
					className={css.navLink}
					activeClassName={css.navLinkActive}
					to={link.path}
					key={link.path}
					text={link.name}
					type={link.type}
					textProps={FullScreenMenu.Item.preset.LARGE}
				/>
			),

			className: cn({ [css.disabled]: link.disabled }),
		}));

		return (
			<nav className={css.nav}>
				<FullScreenMenu
					items={items}
					label={this.menuLabel}
					menuClassName={css.navMenu}
					labelBelow
					placement={FullScreenMenu.placement.VERTICAL}
				/>
			</nav>
		);
	}

	render() {
		return <div className={this.props.className}>{this.renderMenu()}</div>;
	}
}

export const links: {
	[key: string]: {
		[key: string]: Link;
	};
} = {
	main: {
		stories: { type: 'internal', name: t('header.stories'), path: routes.STORIES_PAGE },
		videos: { type: 'internal', name: t('header.videos'), path: routes.VIDEOS_PAGE },
		settings: { type: 'internal', name: t('header.settings'), path: routes.SETTINGS_PAGE },
		learn: {
			type: 'external',
			name: t('header.learn'),
			path: 'https://support.storycards.com',
		},
	},
	none: {},
};

export default withRouter(Navigation);
