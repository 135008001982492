/* eslint-disable no-case-declarations */

import { useEffect } from 'react';
import { IframeTunnel, IFRAME_ACTIONS, AdminReceiverMessageEvent } from 'utils/iframe-tunnel';
import { ResizerEvEm } from 'admin/components/pages/Story/CardEditor/Editor/Resizer/utils';

const IframeMessageReceiver = ({ id }: { id: string }) => {
	useEffect(
		function setup() {
			const tunnel = new IframeTunnel({
				id,
				messageReceiver: message => {
					const { data } = message as AdminReceiverMessageEvent;

					switch (data.action) {
						case IFRAME_ACTIONS.APPLY_SCROLLBAR_FIX:
							const frame = document.querySelector<HTMLIFrameElement>(data.payload.frameSelector);
							if (frame) frame.style.width = data.payload.frameWidth;
							break;
						case IFRAME_ACTIONS.CARD_HEIGHT:
							ResizerEvEm.emit('setCardHeight', data.payload.cardHeight);
							break;
						default:
							break;
					}
				},
				filter: [IFRAME_ACTIONS.APPLY_SCROLLBAR_FIX, IFRAME_ACTIONS.CARD_HEIGHT],
			});
			tunnel.init();

			return () => {
				tunnel.destroy();
			};
		},
		[id]
	);

	return null;
};

export default IframeMessageReceiver;
