import React from 'react';
import cn from 'classnames';
import Text from 'admin/components/common/Text';
import css from './FullScreenMenuHint.scss';

const presets = { DEFAULT: 'DEFAULT', SMALL: 'SMALL', NONE: 'NONE' };

const defaultProps = {
	error: false,
	className: '',
	preset: presets.DEFAULT,
};

type Props = {
	preset?: (typeof presets)[keyof typeof presets];
	className?: string;
	error?: boolean;
} & typeof defaultProps;

const Hint = ({ error, className, preset, ...props }: Props) => (
	<Text {...props} {...Hint.preset[preset]} className={cn(className, css.hint, { [css.itemError]: error })} />
);

Hint.preset = {
	[presets.DEFAULT]: {
		size: Text.size.body,
		weight: Text.weight.normal,
		compact: true,
	},
	[presets.SMALL]: {
		size: Text.size.label,
		weight: Text.weight.normal,
		compact: true,
	},
	[presets.NONE]: {},
};

Hint.defaultProps = defaultProps;

export default Hint;
