import React from 'react';
import cms from 'common/utils/cms';
import { useClientSelector } from 'client/reducers';
import { BBModel, CollectionPaginationType, CollectionPaginationTriggersProp } from 'types/story';
import { selectStorySymbols } from 'client/reducers/story/selectors';
import { CmsEvEm, CmsChangePageEvent } from 'utils/cms/cms-event-emitter';
import { processRepeatableComponents } from 'utils/cms/cms-repeatable-components-processor';
import TraverseTreeDataCollector from 'client/components/common/BuildingBlocks/utils/traverse-tree-data-collector';
import GlobalStyles from 'common/components/GlobalStyles';
import { STORY_ROOT_ID } from 'client/constants/common';
import { getElementIdByNodeId } from 'client/components/common/BuildingBlocks/utils/common';
import PaginationManager from 'utils/cms/pagination-manager';

type CollectionPaginationControllerProps = {
	// current card or story elements to update and listen for pagination events
	scope: 'card' | 'story';
	// current card or story elements to update by pagination events
	data: BBModel[];
	onUpdate: (data: BBModel[]) => void;
};

const CollectionPaginationController: React.FC<CollectionPaginationControllerProps> = ({ data, onUpdate, scope }) => {
	// Listen change page events and update current card or other source with a next portion of elements
	usePageChangeHandler({ data, onUpdate });

	// Get list of components that have pagination
	const paginatedComponents = usePaginatedComponents(scope);

	// Add event listeners for pagination triggers
	usePaginationTriggerListeners(paginatedComponents);

	return paginatedComponents.length ? <GlobalStyles styles={generateTriggersStyle(paginatedComponents)} /> : null;
};

function usePageChangeHandler({ data, onUpdate }: Pick<CollectionPaginationControllerProps, 'data' | 'onUpdate'>) {
	const symbols = useClientSelector(selectStorySymbols);
	const cmsModel = useClientSelector(state => state.story.cms);

	React.useEffect(() => {
		CmsEvEm.addListener('changePage', changePageHandler);

		return () => {
			CmsEvEm.removeListener('changePage', changePageHandler);
		};

		function changePageHandler(event: CmsChangePageEvent) {
			// Update current card or other source with a next portion of elements
			const { data: updatedElements, isModified } = processRepeatableComponents({
				type: 'update-item',
				data,
				symbols,
				items: cmsModel.items,
				...event.data,
			});

			if (!isModified) {
				return;
			}

			onUpdate(updatedElements);
		}
	}, [data, onUpdate, cmsModel, symbols]);

	return null;
}

function usePaginatedComponents(scope: CollectionPaginationControllerProps['scope']) {
	return React.useMemo(() => {
		return TraverseTreeDataCollector.filterComponents(
			component => {
				const {
					collectionPagination = cms.defaultProps.collectionPagination,
					collectionPaginationTriggers = cms.defaultProps.collectionPaginationTriggers,
				} = component.uiConfig.componentProps;

				return (
					collectionPagination !== CollectionPaginationType.none &&
					Object.values(collectionPaginationTriggers).some(Boolean)
				);
			},
			[scope]
		);
	}, [scope]);
}

function usePaginationTriggerListeners(paginatedComponents: ReturnType<typeof usePaginatedComponents>) {
	React.useEffect(() => {
		const controller = new AbortController();
		const rootTarget = document.getElementById(STORY_ROOT_ID);

		const triggersMap = new Map(
			paginatedComponents
				.map(component => {
					const triggers = component.uiConfig.componentProps.collectionPaginationTriggers;
					const baseComponentId = PaginationManager.originMap.get(component._id);
					return baseComponentId && triggers ? [baseComponentId, triggers] : null;
				})
				.filter(Boolean) as [string, CollectionPaginationTriggersProp][]
		);

		triggersMap.forEach((triggers, baseComponentId) => {
			Object.entries(triggers).forEach(([trigger, triggerId]) => {
				document.addEventListener(
					'click',
					event => {
						for (
							let target = event.target as HTMLElement | null;
							target && target !== rootTarget;
							target = target.parentElement
						) {
							if (getElementIdByNodeId(target.id) === triggerId) {
								// prevent target action, for example prevent Button to navigate
								event.stopPropagation();
								// prevent target action, for example prevent Button to navigate
								CmsEvEm.emit('changePage', {
									baseComponentId,
									page: trigger as keyof CollectionPaginationTriggersProp,
								});
								break;
							}
						}
					},
					{ signal: controller.signal, capture: true }
				);
			});
		});

		return () => controller.abort();
	}, [paginatedComponents]);

	return null;
}

function generateTriggersStyle(paginatedComponents: Pick<BBModel, 'uiConfig'>[]) {
	const ids = paginatedComponents
		.map(c => Object.values(c.uiConfig.componentProps.collectionPaginationTriggers ?? {}).filter(Boolean))
		.flat();
	return `${ids.map(id => `[id$="${id}"]`).join(', ')} { cursor: pointer; }`;
}

export default CollectionPaginationController;
