import React from 'react';
import classNames from 'classnames';

import { IUserMemberFieldType } from 'types/user';
import { shortNameByEmail } from 'common/utils/helpers';
import Text from 'admin/components/common/Text';
import { getUserAvatarLink } from 'admin/utils';
import css from './MemberAvatar.scss';

type Props = {
	className?: string;
	user: IUserMemberFieldType;
};

const MemberAvatar: React.FC<Props> = ({ className = '', user }) => {
	const avatarLink = getUserAvatarLink(user.profilePictureFile);

	return (
		<div className={classNames(css.memberAvatar, className)}>
			<div className={css.img} style={{ backgroundImage: `url('${avatarLink}')` }} />
			{!avatarLink && (
				<Text size={Text.size.paragraph} weight={Text.weight.bold} className={css.userName}>
					{shortNameByEmail(user?.email)}
				</Text>
			)}
		</div>
	);
};

export default MemberAvatar;
