import type { CancelVideoParams, CancelVideoResult } from 'src/types/videos';
import { useMutation } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

const cancelVideo = async ({ organizationId, videoId }: CancelVideoParams) => {
	await videos.cancel.params({ organizationId, videoId }).send();
	return { videoId };
};

export const useCancelVideo = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	return useMutation<CancelVideoResult, ApiV2ResponseError, Pick<CancelVideoParams, 'videoId'>>({
		mutationFn: ({ videoId }) => cancelVideo({ organizationId, videoId }),
	});
};
