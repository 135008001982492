import React, { useState, useRef, useCallback } from 'react';
import { Dropdown, MenuProps, DropdownProps } from 'antd';
import cn from 'classnames';
import { getClientFrame } from 'common/utils/iframe-tunnel';
import { useClickOutside } from 'common/components/useClickOutside';
import { useTheme, THEME } from 'admin/components/common/Theme';
import css from './Dropdown.scss';

interface Props extends DropdownProps {
	menu: MenuProps;
	preventCloseOnItemClick?: boolean;
	disableAnimation?: boolean;
	caret?: boolean;
}

const DropdownComponent: React.FC<Props> = ({
	children,
	overlayClassName,
	preventCloseOnItemClick,
	caret = false,
	className,
	...props
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const { theme } = useTheme();
	const [open, setOpen] = useState(false);
	const onOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
		const shouldOpen = info.source === 'trigger' || nextOpen;
		const shouldClose = info.source === 'menu' || !nextOpen;

		if (shouldOpen || (shouldClose && !preventCloseOnItemClick)) {
			setOpen(nextOpen);
		}
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (event.currentTarget === getClientFrame().contentDocument) {
			setOpen(false);
		}
	}, []);

	useClickOutside(ref, handleClickOutside, {
		extraDoc: getClientFrame().contentDocument,
	});

	return (
		<Dropdown
			overlayClassName={cn(css.dropdownO, overlayClassName, {
				[css.noAnimation]: props.disableAnimation,
				[css.dark]: theme === THEME.DARK,
				[css.light]: theme === THEME.LIGHT,
			})}
			className={cn(className, { [css.caret]: caret })}
			{...props}
			open={open}
			onOpenChange={onOpenChange}
		>
			<div ref={ref}>{children}</div>
		</Dropdown>
	);
};

export default DropdownComponent;
