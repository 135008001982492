import type {
	BBModel,
	BBSymbolLink,
	BBSymbolInstance,
	StorySymbols,
	ComponentTypes,
	WithStateAndPlatform,
	BBOtherProp,
} from 'types/story';
import produce, { Draft } from 'immer';
import { COMPONENT_TYPE } from 'common/constants';
import { componentWalk } from 'utils/blocks/component-walk';
import { isSymbolLink, isSymbol } from 'utils/blocks/symbol';
import { findComponentBy } from 'utils/blocks/find-component-by';

type OldId = string;
type NewId = string;
type IdsMap = Map<OldId, { newId: NewId; type: ComponentTypes }>;

export function createIdsMap(): IdsMap {
	return new Map();
}

type ComponentsList = Array<BBModel | BBSymbolLink | BBSymbolInstance>;

/**
 * Updates the `_id` references for linked objects within a duplicated structure.
 * This function traverses the duplicated object and its linked objects, replacing references to old `_id`
 * with newly generated ones to maintain the integrity of relationships.
 */
export function updateReferenceIds<T extends ComponentsList>(components: T, idsMap: IdsMap, symbols: StorySymbols) {
	return produce(components, draft => {
		componentWalk(draft, function walk({ component: draftComponent }) {
			if (isSymbolLink(draftComponent)) return;

			switch (draftComponent.type) {
				case COMPONENT_TYPE.OVERLAY:
					updateOverlayRefs(draftComponent, idsMap, symbols);
					break;
				case COMPONENT_TYPE.SWIPE:
					updateSwipeRefs(draftComponent, idsMap);
					break;
				case COMPONENT_TYPE.LOTTIE:
					updateLottieRefs(draftComponent, idsMap, symbols);
					break;
				default:
					break;
			}

			if (draftComponent.uiConfig?.componentProps?.collectionPaginationTriggers) {
				updatePaginationRefs(draftComponent, idsMap, symbols);
			}

			componentWalk(draftComponent.children, walk);
		});
	});
}

/**
 * Various of block may have links to other blocks by "_id" in `collectionPaginationTriggers` prop;
 * Replace them with a new ones to maintain inter-block connections
 */
function updatePaginationRefs(draftEl: Draft<BBModel | BBSymbolInstance>, idsMap: IdsMap, symbols: StorySymbols) {
	let triggers = draftEl.uiConfig.componentProps.collectionPaginationTriggers;

	if (!triggers && isSymbol(draftEl)) {
		const master = symbols[draftEl.symbol.masterId]?.master;
		const child = findComponentBy([master], { path: '_id', value: draftEl.symbol.childId });
		triggers = child?.component?.uiConfig.componentProps.collectionPaginationTriggers;
	}

	const updateTrigger = (trigger: string = '') => (idsMap.has(trigger) ? idsMap.get(trigger)!.newId : trigger);

	draftEl.uiConfig.componentProps.collectionPaginationTriggers = {
		prev: updateTrigger(triggers?.prev),
		next: updateTrigger(triggers?.next),
	};
}

/**
 * Overlay block may have links to other blocks by "_id" in `overlayTriggers` prop;
 * Replace them with a new ones to maintain inter-block connections
 */
function updateOverlayRefs(draftOverlay: Draft<BBModel | BBSymbolInstance>, idsMap: IdsMap, symbols: StorySymbols) {
	let triggers = draftOverlay.uiConfig.componentProps.overlayTriggers;

	if (!triggers && isSymbol(draftOverlay)) {
		const master = symbols[draftOverlay.symbol.masterId]?.master;
		const child = findComponentBy([master], { path: '_id', value: draftOverlay.symbol.childId });
		triggers = child?.component?.uiConfig.componentProps.overlayTriggers;
	}

	const updateTriggers = (arr: string[] = []) => arr.map(item => (idsMap.has(item) ? idsMap.get(item)!.newId : item));

	draftOverlay.uiConfig.componentProps.overlayTriggers = {
		show: updateTriggers(triggers?.show),
		hide: updateTriggers(triggers?.hide),
	};
}

/**
 * Swipe block may have links to Answer blocks by "_id" in `onSwipe` prop;
 * Replace them with a new ones to maintain inter-block connections
 */
function updateSwipeRefs(draftSwipe: Draft<BBModel | BBSymbolInstance>, idsMap: IdsMap) {
	const { onSwipe } = draftSwipe.uiConfig.componentProps;
	const left = (onSwipe?.left && idsMap.get(onSwipe.left)?.newId) || onSwipe?.left || '';
	const right = (onSwipe?.right && idsMap.get(onSwipe.right)?.newId) || onSwipe?.right || '';

	draftSwipe.uiConfig.componentProps.onSwipe = { left, right };
}

/**
 * Lottie block may have links to other blocks by "_id" in `ltContainer` prop;
 * Replace them with a new ones to maintain inter-block connections
 */
function updateLottieRefs(draftLottie: Draft<BBModel | BBSymbolInstance>, idsMap: IdsMap, symbols: StorySymbols) {
	const otherProps = draftLottie.uiConfig.componentProps.other as WithStateAndPlatform<BBOtherProp>;

	if (!otherProps) return;

	Object.values(otherProps).forEach(draftStateValue => {
		Object.values(draftStateValue).forEach(draftPlatformValue => {
			const { ltContainer } = draftPlatformValue;
			const newId = ltContainer ? idsMap.get(ltContainer)?.newId : undefined;

			if (newId) {
				draftPlatformValue.ltContainer = newId;
			}
		});
	});
}
