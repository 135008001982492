import React, { forwardRef } from 'react';
import cn from 'classnames';
import css from './PageLayout.scss';

type Props = {
	className?: string;
	header?: React.ReactNode;
	children?: React.ReactNode;
	aside?: React.ReactNode;
	content?: React.ReactNode;
};

const PageLayout = forwardRef<HTMLDivElement, Props>((props, ref) => (
	<div className={cn(css.pageLayout, props.className)} ref={ref}>
		<div className={cn(css.header, { [css.empty]: !props.header })}>
			<div /> {/* <div /> required to fill the grid */}
			{props.header}
		</div>
		<div className={css.aside}>{props.aside}</div>
		<div className={css.content}>{props.content}</div>
		{props.children}
	</div>
));

export default PageLayout;
