import React, { useRef } from 'react';
import { debounce, values } from 'lodash';
import cn from 'classnames';

import type { BBCommonProps } from 'types/story';
import { attachRef } from 'common/utils/helpers';
import usePrevious from 'common/components/usePrevious';
import { CONTENT_CONTAINER_ID } from 'client/constants/common';
import { useClientSelector } from 'client/reducers';
import OutbrainWidget from 'client/components/common/OutbrainWidget';
import { useExposeParams } from 'client/components/common/BuildingBlocks/utils/hooks';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import AutoHeight from './AutoHeight';
import css from './Content.scss';

const Content: React.FC<BBCommonProps> = props => {
	const { uiConfig, stateAttrs, eventListeners, transitionCSS } = props;
	const contentRef = useRef<HTMLDivElement>(null);
	const contentInnerRef = useRef<HTMLDivElement>(null);
	const childrenTransitionsRef = useRef(props.resetChildrenTransition());
	const isSelfTransitionCompleteRef = useRef(false);
	const isChildrenTransitionCompleteRef = useRef((Array.isArray(props.children) ? props.children.length : 0) === 0);
	const transitionTimeoutRef = useRef<number | undefined>(0);
	const prevProps = usePrevious(props);
	const propsRef = useRef(props);
	propsRef.current = props;

	const editableModeNodeProps = props.isEditableMode ? { ...props.editableModeProps?.nodeProps } : null;
	const isEmbedAutoHeight = useClientSelector(state => Boolean(state.settings.embed.autoHeight));
	const { duration, delay, effect } = useExposeParams({
		exposeEffect: props.uiConfig.expose?.effect,
		bbType: props.type,
		in: props.in,
	});

	const childTransitionEndCallback: BBCommonProps['onTransitionEnd'] = React.useCallback(child => {
		if (childrenTransitionsRef.current[child._id]) return;

		childrenTransitionsRef.current[child._id] = true;

		if (values(childrenTransitionsRef.current).every(Boolean)) {
			isChildrenTransitionCompleteRef.current = true;
			if (isSelfTransitionCompleteRef.current) {
				propsRef.current.onTransitionEnd({ ...propsRef.current, in: propsRef.current.in });
			}
		}
	}, []);

	// Window resize
	React.useEffect(() => {
		const handleResize = () => {
			if (!contentRef.current) return;
			contentRef.current.style.minHeight = `${window.innerHeight}px`;
		};
		const listener = debounce(handleResize, 50);
		window.addEventListener('resize', listener);
		handleResize();
		return () => {
			window.removeEventListener('resize', listener);
		};
	}, []);

	// Transition end
	React.useEffect(() => {
		const inProp = props.in;
		if (effect && inProp !== undefined && inProp !== prevProps?.in) {
			isSelfTransitionCompleteRef.current = false;
			clearTimeout(transitionTimeoutRef.current);
			transitionTimeoutRef.current = window.setTimeout(
				() => {
					isSelfTransitionCompleteRef.current = true;
					if (isChildrenTransitionCompleteRef.current) props.onTransitionEnd({ ...props, in: inProp });
				},
				effect ? duration + delay : 0
			);
		}
	}, [duration, delay, effect, props, prevProps]);

	return (
		<div
			className={cn(css.contentW, css[`align-${props.contentAlignY}`])}
			ref={attachRef([contentRef, props.containerRef!])}
		>
			<div className={css.contentI} id={CONTENT_CONTAINER_ID}>
				<div
					{...uiConfig.nodeProps}
					{...stateAttrs}
					{...eventListeners}
					{...editableModeNodeProps}
					className={cn(
						transitionCSS,
						css.content,
						uiConfig.nodeProps.className,
						editableModeNodeProps?.className
					)}
					style={{
						...uiConfig.nodeProps.style,
						...editableModeNodeProps?.style,
						...(transitionCSS
							? { transitionDuration: `${duration}ms`, transitionDelay: `${delay}ms` }
							: null),
					}}
					ref={contentInnerRef}
				>
					<AutoHeight contentInnerRef={contentInnerRef} isEmbedAutoHeight={isEmbedAutoHeight}>
						{React.Children.map(props.children, (child: any) => {
							return React.cloneElement(child, { onTransitionEnd: childTransitionEndCallback });
						})}
					</AutoHeight>
				</div>

				<OutbrainWidget />
			</div>
		</div>
	);
};

export default withCardTransitionContext(Content);
