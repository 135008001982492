import * as React from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import _ from 'lodash';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { TextField, Select } from 'admin/components/common/Form';
import { USERNAME_MAX_LEN } from 'admin/components/common/Form/validations';
import t from 'utils/translate';

import Form, { Values } from './Form';

import css from './GeneralSettingsForm.scss';

type Props = {
	className?: string;
	initialValues: Values;
	onSubmit?: (values: Values) => void;
	onDeleteAccount?: () => void;
	renderAccountActions: () => void;
} & typeof GeneralSettingsForm.defaultProps;

type State = {
	countries: { label: string; value: string }[];
};

export default class GeneralSettingsForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
		onSubmit: (values: Values) => {},
	};

	state = {
		countries: [],
	};

	async componentDidMount() {
		const obj = await import('admin/constants/countries.json');

		this.setState({ countries: obj.default });
	}

	renderForm = (formProps: any) => {
		const { countries } = this.state;
		const { dirty, submitting } = formProps;

		return (
			<>
				<Field
					className={css.input}
					name="name"
					component={TextField}
					autoComplete="off"
					isLabelUppercase={false}
					placeholder={t('profile.generalSettingsForm.fields.name.placeholder')}
					label={t('profile.generalSettingsForm.fields.name.label')}
					isRequired
					limit={{ max: USERNAME_MAX_LEN }}
				/>
				<Field
					className={css.input}
					name="email"
					readOnly
					component={TextField}
					autoComplete="off"
					isLabelUppercase={false}
					placeholder={t('profile.generalSettingsForm.fields.email.placeholder')}
					label={t('profile.generalSettingsForm.fields.email.label')}
					isRequired
				/>
				<Field
					className={css.input}
					name="country"
					component={Select}
					menuPosition="absolute"
					options={countries}
					isLabelUppercase={false}
					placeholder={t('profile.generalSettingsForm.fields.country.placeholder')}
					label={{ children: t('profile.generalSettingsForm.fields.country.label') }}
					isRequired
					isSearchable
				/>
				{this.props.renderAccountActions()}
				<div className={css.actionButtons}>
					<Button type="submit" disabled={!dirty || submitting} smallText>
						{t('profile.generalSettingsForm.update')}
					</Button>
					<Button type="button" smallText view="empty" color="danger" onClick={this.props.onDeleteAccount}>
						{t('profile.generalSettingsForm.deleteAccount')}
					</Button>
				</div>
			</>
		);
	};

	render() {
		const { className, initialValues, onSubmit } = this.props;
		const { countries } = this.state;

		return (
			<div className={classNames(css.generalSettingsForm, className)}>
				<Text className={css.heading} weight={Text.weight.semibold} size={Text.size.subtitle}>
					{t('profile.generalSettingsForm.title')}
				</Text>
				{_.size(countries) !== 0 && (
					<Form initialValues={initialValues} className={css.form} onSubmit={onSubmit}>
						{this.renderForm}
					</Form>
				)}
			</div>
		);
	}
}
