import React from 'react';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Column } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';

import { useCancelVideo, useDeleteVideo } from 'admin/queries/videos';

import t from 'utils/translate';
import { IVideo } from 'src/types';
import { MODAL } from 'admin/constants/common';

import css from './VideoActionModal.scss';

export type VideoActionModalData = { videoId: IVideo['id']; organizationId: string; action: 'delete' | 'cancel' };

const VideoActionModal: React.FC<ModalManagerProvidedProps<MODAL.VIDEO_ACTION>> = props => {
	const { mutate: deleteVideo, isPending: isDeleteBusy } = useDeleteVideo();
	const { mutate: cancelVideo, isPending: isCancelBusy } = useCancelVideo();
	const { open, close, data } = props;

	const Title = (
		<Text
			size={Text.size.subtitle}
			weight={Text.weight.semibold}
			compact
			text={t(`videoActionModal.${data.action}.title`)}
		/>
	);

	const onOk = async () => {
		const id = data?.videoId;
		const organizationId = data?.organizationId;

		if (id !== undefined && organizationId !== undefined) {
			if (data.action === 'delete') deleteVideo({ videoId: id });
			if (data.action === 'cancel') cancelVideo({ videoId: id });
		}

		close();
	};

	return (
		<Modal title={Title} open={open} destroyOnClose width={564} className={css.modal}>
			<ModalBody className={css.body}>
				<Text size={Text.size.paragraph} text={t(`videoActionModal.${data.action}.text`)} />
			</ModalBody>
			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button view="primary" onClick={close}>
						{t(`videoActionModal.${data.action}.discard`)}
					</Button>
					<Button view="secondary-danger" onClick={onOk} loading={isDeleteBusy || isCancelBusy}>
						{t(`videoActionModal.${data.action}.confirm`)}
					</Button>
				</Column>
			</ModalFooter>
		</Modal>
	);
};

export default VideoActionModal;
