import React, { forwardRef, useMemo } from 'react';
import { sortBy, map, noop, head, get } from 'lodash';
import cn from 'classnames';
import { Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import type { IUser } from 'src/types';
import Text from 'admin/components/common/Text';
import ScrollBox from 'admin/components/common/ScrollBox';
import t from 'utils/translate';
import { routes } from 'admin/constants';

import FloatingBox from './FloatingBox';
import css from './User.scss';

type Props = {
	isOpen: boolean;
	user: IUser;
	view: 'full' | 'minimal';
	onOrganizationClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onLogOutClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Menu = forwardRef<HTMLDivElement, Props>(({ user, onOrganizationClick, onLogOutClick, isOpen, view }, ref) => {
	const activeOrganizationId = user.organization?.id;
	const organizations = useMemo(() => sortBy(user.organizations ?? [], ['name']), [user.organizations]);
	const isFullView = view === 'full';
	const location = useLocation();

	if (!isOpen) return null;

	return (
		<FloatingBox className={css.userMenu} ref={ref}>
			{isFullView ? (
				<>
					<div className={css.section}>
						<Text size="label" compact>
							{user.member.name || user.member.email}
						</Text>
					</div>
					<div className={css.section}>
						<Text className={css.title} weight="semibold" size="description" compact>
							{t('user.workspaces')}
						</Text>
						<ScrollBox shadowHexColor="#7a7a7a" maxHeight="80vh">
							{map(organizations, org => {
								const isPending = org?.organizationMembers?.status === 'pending';
								const name = (
									<Text
										className={css.userOrganizationName}
										size="label"
										weight={activeOrganizationId === org.id ? 'semibold' : 'normal'}
										compact
									>
										{get(org, 'name')}
									</Text>
								);

								return (
									<button
										key={org.id}
										data-id={org.id}
										onClick={isPending ? noop : onOrganizationClick}
										type="button"
										className={cn(css.userOrganization, { [css.pending]: isPending })}
									>
										<Text size="label" weight="normal" compact>
											<span className={css.orgAvatar}>{head(get(org, 'name'))}</span>
										</Text>
										{isPending ? (
											<Tooltip
												destroyTooltipOnHide
												placement="topRight"
												title={() => (
													<Text
														size="body"
														text="Invitation isn't accepted. Please check your email."
													/>
												)}
											>
												{name}
											</Tooltip>
										) : (
											name
										)}
									</button>
								);
							})}
						</ScrollBox>
					</div>
					<div className={css.section}>
						<div className={css.item}>
							{location.pathname === routes.PROFILE_PAGE ? (
								<Text size={Text.size.label} compact>
									{t('user.profile')}
								</Text>
							) : (
								<Link to={routes.PROFILE_PAGE}>
									<Text size={Text.size.label} compact>
										{t('user.profile')}
									</Text>
								</Link>
							)}
						</div>
						<div className={css.item}>
							<button type="button" onClick={onLogOutClick}>
								<Text size={Text.size.label} compact>
									{t('user.logout')}
								</Text>
							</button>
						</div>
					</div>
				</>
			) : (
				<>
					<div className={css.section}>
						<Text size={Text.size.label} compact>
							{user.member.name || user.member.email}
						</Text>
					</div>
					<div className={css.section}>
						<button className={css.logoutBtn} type="button" onClick={onLogOutClick}>
							<Text tag="span" size={Text.size.label} compact>
								{t('user.logout')}
							</Text>
						</button>
					</div>
				</>
			)}
		</FloatingBox>
	);
});

Menu.displayName = 'UserMenu';

export default Menu;
