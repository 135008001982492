import type { UpdateVideoParams, UpdateVideoResult } from 'src/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { QUERY_KEYS } from '../constants';

const updateVideo = async ({ organizationId, videoId, ...queryParams }: UpdateVideoParams) => {
	const response: ApiV2Response<UpdateVideoResult> = await videos.getStats
		.params({ organizationId, videoId })
		.send(queryParams);

	return response.body!;
};

export const useUpdateVideo = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const client = useQueryClient();

	return useMutation<UpdateVideoResult, ApiV2ResponseError, Pick<UpdateVideoParams, 'videoId' | 'poster' | 'title'>>({
		mutationFn: ({ videoId, poster, title }) => updateVideo({ organizationId, videoId, poster, title }),
		onSuccess: async (data, variables, context) => {
			/**
			 * invalidate `useVideoStats` query to get fresh data
			 * we don't invalidate `useVideo` query because we are
			 * receiving an event in pubnub about video update
			 * and invalidate this query from pubnub module
			 */
			return client.invalidateQueries({ queryKey: [QUERY_KEYS.VIDEO_STATS, organizationId, variables.videoId] });
		},
	});
};
