import * as React from 'react';
import classNames from 'classnames';
// import _ from 'lodash';

import t from 'utils/translate';
import Text from 'admin/components/common/Text';

import css from './UploadAvatar.scss';

const defaultProps = {
	className: '',
	avatar: '',
};

type Props = {
	className?: string;
	avatar?: string;
	onUpload: (asset: File) => Promise<any>;
} & typeof defaultProps;

type State = {
	isBusy: boolean;
};

// eslint-disable-next-line react/prefer-stateless-function
export default class UploadAvatar extends React.Component<Props, State> {
	static defaultProps = defaultProps;

	public readonly state = {
		isBusy: false,
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.avatar !== this.props.avatar && this.state.isBusy) {
			setTimeout(() => this.setState({ isBusy: false }), 0);
		}
	}

	onAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files instanceof FileList ? e.target.files[0] : null;
		this.setState({ isBusy: true });

		if (file) {
			await this.props.onUpload(file);
		}

		this.setState({ isBusy: false });
	};

	render() {
		const { className, avatar } = this.props;
		const { isBusy } = this.state;
		return (
			<div className={classNames(css.uploadAvatar, isBusy && css.isBusy, className)}>
				<div className={css.avatarPreview} style={{ backgroundImage: `url('${avatar}')` }}>
					{isBusy && (
						<div className={css.preloader}>
							<svg viewBox="25 25 50 50">
								<circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
							</svg>
						</div>
					)}
				</div>
				<div className={css.inputWrap}>
					<label htmlFor="avatar-upload">
						<Text>{t('profile.changeAvatar')}</Text>
						<input id="avatar-upload" type="file" onChange={this.onAvatarUpload} />
					</label>
				</div>
			</div>
		);
	}
}
