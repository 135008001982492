import type { ReactQueryMeta } from 'types/react-query';

import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import translate from 'common/utils/translate';
import { getResponseErrorMsg } from 'common/resources/api-response';
import { toast } from 'admin/components/common/MessageContainer/toast';

const MINUTE = 1000 * 60;

interface OnErrorOptions {
	toast?: ReactQueryMeta['errorToast'];
}

const onError = (error: Error, options: OnErrorOptions = {}) => {
	const errorToast: OnErrorOptions['toast'] = { timeout: 3, enabled: true, ...options.toast };
	const errorMessage = getResponseErrorMsg(error as IBackendResponseError, translate('responseError.badRequest'));
	if (errorToast.enabled && errorMessage) toast.error(errorMessage, errorToast.timeout);

	// todo: consider to handle `responseError.<statusCode>` as well as `createThunkAction` does
};

const queryCache = new QueryCache({
	onError: (error, query) => {
		onError(error, { toast: query.meta?.errorToast });
	},
});

const mutationCache = new MutationCache({
	onError: (error, variables, context, mutation) => {
		onError(error, { toast: mutation.meta?.errorToast });
	},
});

function clientFactory() {
	return new QueryClient({
		queryCache,
		mutationCache,
		defaultOptions: {
			queries: {
				staleTime: MINUTE,
				gcTime: 5 * MINUTE,
				refetchOnWindowFocus: false,
			},
		},
	});
}

const client = clientFactory();

export default client;
