import React from 'react';
import classNames from 'classnames';

import type { BBCommonProps } from 'types/story';
import { cutURLMethod } from 'common/utils/assets';
import { OTHER_PROPS } from 'common/constants/component-props';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { useExposeParams, useTransitionEndTimeout } from 'client/components/common/BuildingBlocks/utils/hooks';
import { ScriptRenderer } from 'client/components/common/ScriptRenderer/ScriptRenderer';
import { VideoPlayer } from 'common/components/VideoPlayer';

import css from './Card.scss';

type CardProps = BBCommonProps;

const Card: React.FC<CardProps> = props => {
	const { uiConfig, stateAttrs, eventListeners, transitionCSS } = props;
	const backgroundVideo = cutURLMethod(uiConfig.componentProps.other?.[OTHER_PROPS.card.backgroundVideo] ?? '');
	const backgroundPoster = cutURLMethod(uiConfig.componentProps.other?.[OTHER_PROPS.card.backgroundPoster] ?? '');
	const editableModeNodeProps = props.isEditableMode ? { ...props.editableModeProps?.nodeProps } : null;

	const { duration, delay } = useExposeParams({
		exposeEffect: props.uiConfig.expose?.effect,
		bbType: props.type,
		in: props.in,
	});

	useTransitionEndTimeout(props);

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...eventListeners}
			{...editableModeNodeProps}
			style={{
				...uiConfig.nodeProps.style,
				...editableModeNodeProps?.style,
				...(transitionCSS ? { transitionDuration: `${duration}ms`, transitionDelay: `${delay}ms` } : null),
			}}
			className={classNames(
				css.card,
				transitionCSS,
				uiConfig.nodeProps.className,
				editableModeNodeProps?.className
			)}
			ref={props.containerRef as React.RefObject<HTMLDivElement>}
		>
			<ScriptRenderer
				value={props.uiConfig.componentProps?.other?.embedCode ?? ''}
				disabled={props.isEditableMode}
				scriptOnly
			/>
			<div className={css.tint} style={{ background: uiConfig.componentProps.other[OTHER_PROPS.card.tint] }} />
			{backgroundVideo && backgroundVideo !== 'none' && (
				<VideoPlayer
					className={css.videoBg}
					src={backgroundVideo}
					poster={backgroundPoster}
					muted={uiConfig.componentProps.other[OTHER_PROPS.card.muted] ?? true}
					loop={uiConfig.componentProps.other[OTHER_PROPS.card.loop] ?? false}
					autoplay={!props.isEditableMode}
					controls={false}
				/>
			)}
		</div>
	);
};

export default withCardTransitionContext(Card);
