import produce from 'immer';
import { get, forEach, merge, set, values } from 'lodash';

import {
	CARD_ROOT_ELEMENT_INDEX as CREI,
	CARD_TYPE,
	COMPONENT_TYPE,
	LAYER_TYPE,
	STORY_ROOT_ELEMENT_INDEX as SREI,
} from 'common/constants';
import type {
	BBModel,
	CardData,
	CardTemplate,
	CardTemplateWithoutData,
	StoryMediaQuery,
	StoryModel,
	StoryStep,
	StorySymbols,
} from 'types/story';
import { isSymbolLink } from 'utils/blocks/symbol';
import { CardFacade, StoryFacade } from 'utils/facades';
import { componentWalk } from 'utils/blocks/component-walk';
import { CardEventFactory } from 'utils/facades/templates/card-event';
import { generateCardId, generateElementId, generateId } from 'utils/generate-id';
import { createStep } from 'admin/components/pages/Story/Flow/Connector/ConnectorSvg/utils';

export const DEFAULT_LAST_CARD_TYPE = CARD_TYPE.INFO;

export function getCardType(template: CardTemplate | CardTemplateWithoutData) {
	let type: CardData['type'] = CARD_TYPE.INFO;

	forEach(CARD_TYPE, (ct: CardData['type']) => {
		if (template.categories.some(t => t.toLowerCase() === ct.toLowerCase())) {
			type = ct;
		}
	});

	return type;
}

/**
 * The template contains an identifier for the card and its components, symbols, etc.
 * These identifiers must be unique within the story.
 * To do this, you need to replace all their matches in the template with new ones.
 *
 * For example
 * template.card._id - is listed also in storySettings.cards[cardId]
 * template...component._id - can be listed in storySetting also (eg. Answer)
 * template...component.symbol.masterId | component.symbol.instanceId | component.symbol.childId -
 *      they are also listed in story.data.symbols
 */
function updateTemplateIds(template: CardTemplate) {
	let templateStr = JSON.stringify(template);
	const bbIdsSet = new Set<string>();
	const cardId = template.editor.card._id;
	const symbolsCollection = values(template.editor.symbols ?? {}).flatMap(o => [o.master, ...values(o.instance)]);

	// Get building blocks id
	componentWalk<BBModel>(
		[...template.editor.card.elements, ...(template.editor.storyElements ?? []), ...symbolsCollection],
		function walk({ component }) {
			if (!isSymbolLink(component) /* skip symbol link due entire symbol included at `symbolsCollection` */) {
				bbIdsSet.add(component._id);
				componentWalk(component.children, walk);
			}
		}
	);

	// #1
	// find in templateStr all card id by regex and replace with new id
	templateStr = templateStr.replace(new RegExp(`"${cardId}"`, 'g'), `"${generateCardId()}"`);

	// #2
	// find in templateStr all Object.keys(bbIds) by regex and replace with new id
	bbIdsSet.forEach(id => {
		templateStr = templateStr.replace(new RegExp(`${id}"`, 'g'), `${generateElementId()}"`);
	});

	// #3
	// return updated json
	return JSON.parse(templateStr);
}

function modifyTemplateSymbolNames(template: CardTemplate) {
	const { symbols } = template.editor;

	if (!symbols || Object.keys(symbols).length === 0) {
		return template;
	}

	return produce(template, draft => {
		Object.entries(symbols).forEach(([k]) => {
			// eslint-disable-next-line no-param-reassign
			(draft.editor.symbols as StorySymbols)[k].master.uiConfig.editorProps.name += `-${generateId(2)}`;
		});
	});
}

function adaptToDefaultPlatform(template: CardTemplate, defaultPlatform: StoryMediaQuery['defaultPlatform']) {
	const tPlatform = template.metadata.defaultPlatform;

	if (tPlatform === defaultPlatform) {
		return template;
	}

	function update(array: BBModel[]) {
		const path = ['uiConfig.componentProps.other', 'uiConfig.componentProps.styles'];

		componentWalk<BBModel>(array, function walk({ component }) {
			path.forEach(p => {
				const obj = get(component, p, {});
				Object.keys(obj).forEach(state => {
					obj[state][defaultPlatform] = merge(obj[state][tPlatform], obj[state][defaultPlatform]);
					delete obj?.[state]?.[tPlatform];
				});
			});

			const { children } = component;

			componentWalk(children, walk);

			/* Update children
			 * Components like "TEXT" or "RESULT_TEXT" have a children as object with a pair
			 * { key: value }, where key is a "platform" and value is a "text".
			 * Values of only these components can be inherited from other platform.
			 */
			if (children !== null && typeof children === 'object' && !Array.isArray(children)) {
				Object.keys(children).forEach(state => {
					children[state][defaultPlatform] = children[state][defaultPlatform] || children[state][tPlatform];
					delete children?.[state]?.[tPlatform];
				});
			}
		});
	}

	return produce(template, _template => {
		// update components
		update(_template.editor.card.elements);

		// update symbols
		Object.keys(_template.editor.symbols || {}).forEach(masterId => {
			const symbols = _template.editor.symbols as StorySymbols;
			const { master, instance } = symbols[masterId];

			// update master
			update([master]);

			// update instances
			Object.keys(instance).forEach(instanceId => {
				update([instance[instanceId]]);
			});
		});
	});
}

type AddCardTemplatesToStoryParams = {
	// story version
	version: string;
	story: StoryModel;
	mediaQuery: StoryMediaQuery;
	cardTemplates: CardTemplate[];
	isNewCardFromScratch: boolean;
	currentStep: { step: StoryStep; index: number };
};

export function addCardTemplatesToStory({
	cardTemplates,
	story,
	version,
	mediaQuery,
	isNewCardFromScratch,
	currentStep,
}: AddCardTemplatesToStoryParams) {
	let nextStory = { ...story };
	const cards: CardData[] = [];

	cardTemplates.forEach(function processTemplate(_template, index) {
		let template = _template;
		const isFirstTemplate = index === 0;

		if (!isNewCardFromScratch) {
			// Generate new unique ids
			template = updateTemplateIds(template);

			// Generate unique master symbol names
			template = modifyTemplateSymbolNames(template);

			// Adapt to default platform
			template = adaptToDefaultPlatform(template, mediaQuery.defaultPlatform);
		}

		const card = new CardFacade({
			...template.editor.card,
			events: [CardEventFactory.create('default', {})],
		});

		card.transformElementsDefaults({ mediaQuery });

		// Get card.data and add story float elements references into it
		const cardData = produce(card.data, draft => {
			const StoryFacadeInstance = new StoryFacade(nextStory, version);
			const storyFLA = StoryFacadeInstance.elements[SREI[COMPONENT_TYPE.FLOAT_ABOVE]].children as BBModel[];
			const storyFLB = StoryFacadeInstance.elements[SREI[COMPONENT_TYPE.FLOAT_BELOW]].children as BBModel[];

			// Add current story elements references into the selected card template
			draft.elements[CREI[COMPONENT_TYPE.FLOAT_ABOVE]].children.push(...storyFLA);
			draft.elements[CREI[COMPONENT_TYPE.FLOAT_BELOW]].children.push(...storyFLB);

			// This code adds template `editor.storyElements` into the card and adjusts the `uiConfig.layer` property
			// for these elements to ensure they are fixed on the card level and no longer considered story elements.
			const templateStoryElements = {};
			template.editor.storyElements?.forEach(bb => {
				componentWalk(bb.children, ({ component }) => {
					templateStoryElements[component._id] = produce(component, componentDraft => {
						componentWalk([componentDraft], function walkChildren({ component: child }) {
							set(child, 'uiConfig.layer', LAYER_TYPE.FIXED);
							componentWalk(child.children, walkChildren);
						});
					});
				});
			});
			componentWalk(
				[
					...(draft.elements[CREI.FLOAT_ABOVE].children as BBModel[]),
					...(draft.elements[CREI.FLOAT_BELOW].children as BBModel[]),
				],
				function callback({ component }) {
					if (templateStoryElements[component._id]) {
						set(component, 'uiConfig', templateStoryElements[component._id].uiConfig);
						set(component, 'children', templateStoryElements[component._id].children);
					}
				}
			);
		});

		// collect complete card template
		cards.push(cardData);

		const newStep = produce(isFirstTemplate ? currentStep.step : createStep(), draftStep => {
			draftStep.cards.push(cardData);
		});

		// Update story with a new template #1
		nextStory = produce(nextStory, draft => {
			const { steps } = new StoryFacade(draft, version);
			if (isFirstTemplate) {
				// add template to current existed step
				steps[currentStep.index] = newStep;
			} else {
				// add template to new step ahead
				steps.splice(currentStep.index + index, 0, newStep);
			}
		});

		// Update story with a new template #2
		nextStory = produce(nextStory, draft => {
			/* eslint-disable no-param-reassign */

			// Merge story card settings
			draft.storyVersions[version].settings.cards = merge(
				draft.storyVersions[version].settings.cards,
				template.metadata.storySettings.cards
			);

			// Merge symbols
			draft.storyVersions[version].data.symbols = merge(
				draft.storyVersions[version].data.symbols,
				template.editor.symbols
			);

			// Merge fonts
			const currentFonts = Object.values(draft.storyVersions[version].settings.fonts ?? {});
			Object.values(template.metadata.storySettings.fonts ?? {}).forEach(font => {
				const has = currentFonts.some(f => f.fontType === font.fontType && f.fontFamily === font.fontFamily);
				if (!has) {
					draft.storyVersions[version].settings.fonts = {
						...draft.storyVersions[version].settings.fonts,
						[font.id]: font,
					};
				}
			});
			/* eslint-enable no-param-reassign */
		});
	});

	return {
		// updated story version
		story: new StoryFacade(nextStory, version).story,
		// facades with an added cards
		cards,
	};
}
