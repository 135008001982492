/* eslint-disable no-await-in-loop */
import { sleep } from 'utils/helpers';
import { logout } from 'admin/actions/logout';
import type { ThunkActionType } from 'common/thunk-action';
import type { AdminReducerState, AdminThunkDispatch } from 'admin/reducers';
import { getResponseErrorMsg, getResponseErrors } from 'common/resources/api-response';
import { SET_LOADING } from 'admin/constants/actions';
import { toast } from 'admin/components/common/MessageContainer/toast';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import translate from 'utils/translate';

export type UploadParams = {
	apiUrl: string;
	sessionId: string;
	asset?: File;
	url?: string;
	dataUrl?: string;
	method?: string;
};

export const uploadFileHelper = async (params: UploadParams): Promise<any> => {
	const { asset, url, dataUrl } = params;

	if (asset) {
		return new Promise((resolve, reject) => {
			const XHR = new XMLHttpRequest();
			const data = new FormData();

			data.append('asset', asset);

			XHR.addEventListener('load', event => {
				// @ts-expect-error ts-migrate FIXME
				if (JSON.parse(event.target.responseText).success) {
					// @ts-expect-error ts-migrate FIXME
					resolve(JSON.parse(event.target.responseText));
				} else {
					// @ts-expect-error ts-migrate FIXME
					reject(JSON.parse(event.target.responseText));
				}
			});
			XHR.addEventListener('error', e => reject(e));
			XHR.open(params.method || 'POST', `${params.apiUrl}`);
			XHR.setRequestHeader('x-session-token', params.sessionId || '');
			XHR.send(data);
		});
	}

	const response = await fetch(params.apiUrl, {
		method: params.method || 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			'x-session-token': params.sessionId || '',
		},
		body: JSON.stringify({
			data: { url, dataUrl },
		}),
	});

	return response.json();
};

// utility function to handle cough 403 error while create/delete/edit story
export const handleStoryNoAccessError = (dispatch: AdminThunkDispatch, error: IBackendResponseError) => {
	const statusCode = getResponseErrors(error)?.[0]?.statusCode || error.status;
	if (statusCode === 403) {
		dispatch(logout());
		throw new AdminError(translate('responseError.403'));
	}
};

export const handleAPIv2Error = (error: ApiV2ResponseError) => {
	const msg = error?.response?.body?.message;
	if (msg) throw new AdminError(msg);
};

interface ThunkArgs {
	dispatch: AdminThunkDispatch;
	getState: () => AdminReducerState;
}

interface ThunkActionOptions<Result, ActionParams> {
	type?: ThunkActionType;
	payloadCreator: (args: ActionParams, thunkArgs: ThunkArgs) => Promise<IBackendBody<Result>>;
	// todo: get rid of error any type
	onError?: (args: { params: ActionParams; error: any; dispatch: ThunkArgs['dispatch'] }) => void;
	skipStarted?: boolean;
	skipFailed?: boolean;
	skipSucceed?: boolean;
	skipLoading?: boolean;
	skipAlert?: boolean;
	// ms
	alertDuration?: number;
	retries?: number;
	// ms
	retriesTimeout?: number;
}

export const createThunkAction = <Result = null, ActionParams = void>({
	type,
	payloadCreator,
	onError,
	skipStarted,
	skipFailed,
	skipSucceed,
	skipLoading,
	skipAlert,
	alertDuration = 3000,
	retries = 1,
	retriesTimeout = 3000,
}: ThunkActionOptions<Result, ActionParams>) => {
	return (params: ActionParams) =>
		async (dispatch: ThunkArgs['dispatch'], getState: ThunkArgs['getState']): Promise<IBackendBody<Result>> => {
			let attemptsLeft = Math.max(1, retries);
			const hasType = type !== undefined;

			// requestStarted
			if (hasType) {
				if (!skipStarted) dispatch({ type: type.PENDING });
				if (!skipLoading) dispatch({ type: SET_LOADING, payload: { [type.PENDING]: true } });
			}

			let result: IBackendBody<Result> = { success: false };

			try {
				while (attemptsLeft > 0) {
					try {
						const response = await payloadCreator(params, { dispatch, getState });
						result = response && 'success' in response ? response : { success: true };
						break; // Break the loop on successful response
					} catch (error) {
						attemptsLeft -= 1;
						if (attemptsLeft === 0) throw error; // Rethrow the error if all attempts fail
						await sleep(retriesTimeout);
					}
				}
			} catch (firstError) {
				const error = firstError as IBackendResponseError;
				const responseErrors = getResponseErrors(error);
				let errorMessage = getResponseErrorMsg(error, translate('responseError.badRequest'));

				try {
					if (onError) {
						onError({ error, params, dispatch });
					}

					const statusCode = responseErrors?.[0]?.statusCode || error.status;
					switch (statusCode) {
						case 503:
							errorMessage = translate('responseError.503');
							break;
						case 502:
							errorMessage = translate('responseError.502');
							break;
						case 404:
							errorMessage = translate('responseError.404');
							break;
						case 403:
							errorMessage = translate('responseError.403');
							break;
						default:
							break;
					}
				} catch (secondError) {
					errorMessage = (secondError as Error)?.message;
				}

				if (hasType) {
					if (!skipFailed) dispatch({ type: type.REJECTED, payload: errorMessage });
					if (!skipLoading) dispatch({ type: SET_LOADING, payload: { [type.PENDING]: false } });
				}

				if (!skipAlert) {
					toast.error(errorMessage, alertDuration / 1000);
				}

				return { success: false, errors: responseErrors };
			}

			// requestSucceeded
			if (hasType) {
				if (!skipSucceed) dispatch({ type: type.FULFILLED, payload: result });
				if (!skipLoading) dispatch({ type: SET_LOADING, payload: { [type.PENDING]: false } });
			}

			return result;
		};
};
