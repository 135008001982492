import type { GetVideoStatsParams, GetVideoStatsResult } from 'src/types';
import { useQuery } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { QUERY_KEYS } from '../constants';

const getVideoStats = async ({ organizationId, videoId, ...queryParams }: GetVideoStatsParams) => {
	const response: ApiV2Response<GetVideoStatsResult> = await videos.getStats
		.params({ organizationId, videoId })
		.send(queryParams);

	return response.body;
};

export const useVideoStats = (params: Omit<GetVideoStatsParams, 'organizationId'>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { videoId, ...queryParams } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.VIDEO_STATS, organizationId, videoId, JSON.stringify(queryParams)],
		queryFn: () => getVideoStats({ organizationId, videoId, ...queryParams }),
		enabled: Boolean(videoId),
	});
};
