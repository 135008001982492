import type { StoryMediaPlatform } from 'types/story';
import { EventEmitter } from 'utils/event-emitter';

export type ResizerSetDisabledEvent = { type: 'setDisabled'; data: boolean };

export type ResizerSetCardHeightEvent = { type: 'setCardHeight'; data: number };

export type ResizerMousedownEvent = { type: 'resizerMousedown'; data: { pageX: number } };

type ResizerEvent = ResizerSetDisabledEvent | ResizerSetCardHeightEvent | ResizerMousedownEvent;

export const ResizerEvEm = new EventEmitter<ResizerEvent>();

interface GetInitialPlatformWidthProps {
	platform: StoryMediaPlatform;
	min: number;
	max: number;
	containerWidth: number;
}

export function getInitialPlatformWidth(props: GetInitialPlatformWidthProps): string {
	const containerOffset = 0;

	switch (props.platform) {
		case 'desktop':
		case 'tablet':
			return `${Math.max(props.min, Math.min(props.containerWidth - containerOffset, props.max))}px`;
		case 'mobileLandscape':
			return `${Math.min(667, props.max)}px`;
		case 'mobile':
			return `${Math.min(375, props.max)}px`;
		default:
			return '';
	}
}
