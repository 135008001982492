import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';

import type { BBCommonProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { withFixedWrapper } from 'client/components/common/BuildingBlocks/FixedWrapper';
import { useExposeParams, useTransitionEndTimeout } from 'client/components/common/BuildingBlocks/utils/hooks';
import css from './FloatBelow.scss';

const FloatBelow: React.FC<BBCommonProps> = props => {
	const { uiConfig, stateAttrs, eventListeners, transitionCSS } = props;
	const editableModeNodeProps = props.isEditableMode ? { ...props.editableModeProps?.nodeProps } : null;
	const { duration, delay } = useExposeParams({
		exposeEffect: props.uiConfig.expose?.effect,
		bbType: props.type,
		in: props.in,
	});

	useTransitionEndTimeout(props);

	const onChildTransitionEnd: BBCommonProps['onTransitionEnd'] = React.useCallback(child => {
		// do nothing -> 155d3993
	}, []);

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...eventListeners}
			{...editableModeNodeProps}
			className={classNames(
				transitionCSS,
				css.floatBelow,
				uiConfig.nodeProps.className,
				editableModeNodeProps?.className
			)}
			style={{
				...uiConfig.nodeProps.style,
				...editableModeNodeProps?.style,
				...(transitionCSS ? { transitionDuration: `${duration}ms`, transitionDelay: `${delay}ms` } : null),
			}}
			ref={props.containerRef as React.RefObject<HTMLDivElement>}
		>
			{map(React.Children.toArray(props.children), (child: React.ReactElement<BBCommonProps>) =>
				withFixedWrapper({
					isCardTree: child.props.isCardTree,
					key: child.props._id,
					layer: child.props.uiConfig.layer,
					zIndex: child.props.uiConfig.nodeProps?.style?.zIndex,
					children: React.cloneElement(child, { onTransitionEnd: onChildTransitionEnd }),
				})
			)}
		</div>
	);
};

export default withCardTransitionContext(FloatBelow);
