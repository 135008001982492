import type { GetVideoParams, GetVideoResult } from 'src/types';
import { useQuery } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { QUERY_KEYS } from '../constants';

export const getVideoQueryFn = async ({ organizationId, videoId }: GetVideoParams) => {
	const response: ApiV2Response<GetVideoResult> = await videos.getById.params({ organizationId, videoId }).send();
	return response.body;
};
export const getVideoQueryKey = ({ organizationId, videoId }: GetVideoParams) => {
	return [QUERY_KEYS.VIDEO_ITEM, organizationId, videoId];
};

export const useVideo = (params: Omit<GetVideoParams, 'organizationId'>, options?: { enabled?: boolean }) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const { videoId } = params;
	const enabled = options?.enabled ?? true;

	return useQuery({
		queryKey: getVideoQueryKey({ organizationId, videoId }),
		queryFn: () => getVideoQueryFn({ organizationId, videoId }),
		enabled: Boolean(videoId) && enabled,
		select: data => data?.transcoding,
	});
};
