import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { initialize as initializeForm } from 'redux-form';
import _ from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';

import { routes } from 'admin/constants';
import { AdminReducerState } from 'admin/reducers';
import PageLayout from 'admin/components/common/PageLayout';
import ContentLayout from 'admin/components/common/PageLayout/ContentLayout';
import Tag, { type Props as TagProps } from 'admin/components/common/Tag';
import Alert from 'admin/components/common/Alert';
import User from 'admin/components/common/User';
import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import { getUserAvatarLink } from 'admin/utils';
import Navigation, { links } from 'admin/components/common/Navigation';
import { setModal, updateMember, updateMemberAvatar, auth } from 'admin/actions';

import { MODAL, FORM_MODEL } from 'admin/constants/common';

import UploadAvatar from './UploadAvatar';
import GeneralSettingsForm from './GeneralSettingsForm';
import { Values } from './GeneralSettingsForm/Form';

import css from './Profile.scss';

const mapStateToProps = ({ user }: AdminReducerState) => ({ user });

const mapDispatchToProps = {
	updateMember,
	updateMemberAvatar,
	initializeForm,
	setModal,
	enableTOTP: auth.post.totp,
	checkTOTP: auth.get.totp,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

type AccountAction = {
	title: string;
	onClick: () => void;
	label?: { text: string; color: TagProps['color'] };
	disabled?: boolean;
};

class Profile extends React.Component<Props> {
	async componentDidMount() {
		this.props.checkTOTP();
	}

	componentDidUpdate(prevProps: Props) {
		const { user } = this.props;
		const { user: prevUser } = prevProps;

		if (_.get(prevUser, 'member.email') !== _.get(user, 'member.email')) {
			this.props.initializeForm(FORM_MODEL.PROFILE_GENERAL_SETTINGS, {
				name: _.get(user, 'member.name'),
				email: _.get(user, 'member.email'),
				country: _.get(user, 'member.country'),
			});
		}
	}

	get accountActions(): Record<'main' | 'twoFA', AccountAction[]> {
		const { user } = this.props;

		return {
			main: [
				{
					title: t('profile.accountActions.2fa'),
					onClick: () => this.props.history.push(routes.PROFILE_2FA_PAGE),
					label: { text: this.twoFAStatus.status, color: this.twoFAStatus.color },
				},
				{
					title: t('profile.accountActions.changeEmail'),
					onClick: () => this.props.setModal({ id: MODAL.CHANGE_USER_EMAIL }),
				},
				{
					title: t('profile.accountActions.changePass'),
					onClick: () => this.props.setModal({ id: MODAL.CHANGE_USER_PASSWORD }),
				},
			],
			twoFA: [
				{
					title: t('profile.2fa.options.mobileApp'),
					onClick: async () => {
						if (this.twoFAStatus.status === '') {
							const result = await this.props.enableTOTP({ type: 'app' });

							if (result.success) {
								await this.props.checkTOTP();
								this.props.setModal({ id: MODAL.TWO_FA });
							}
						} else if (this.twoFAStatus.status === 'setup') {
							this.props.setModal({ id: MODAL.TWO_FA });
						}
					},
					label: { text: this.twoFAStatus.status, color: this.twoFAStatus.color },
					disabled: this.twoFAStatus.status === 'enabled',
				},
				this.twoFAStatus.status === 'enabled' &&
					user.auth.totp.totp?.recoveryCodes?.length && {
						title: t('profile.2fa.options.backupCodes'),
						onClick: () =>
							this.props.setModal({
								id: MODAL.TWO_FA_BACKUP_CODES,
								data: { codes: user.auth.totp.totp.recoveryCodes },
							}),
					},
				user.auth.totp.totp?.verified && {
					title: t('profile.2fa.options.remove'),
					onClick: () =>
						this.props.setModal({
							id: MODAL.TWO_FA_REMOVAL,
						}),
				},
			].filter(Boolean) as AccountAction[],
		};
	}

	get twoFAStatus(): { status: 'enabled' | 'setup' | ''; color: TagProps['color'] } {
		const { user } = this.props;
		const isTwoFAEnabled = Boolean(user.auth?.totp?.enabled);
		const isTwoFAVerified = Boolean(user.auth?.totp?.totp?.verified);

		return {
			status: isTwoFAVerified ? 'enabled' : isTwoFAEnabled ? 'setup' : '',
			color: isTwoFAVerified ? 'success' : 'danger',
		};
	}

	onGeneralSettingsFormSubmit = async (values: Values) => {
		await this.props.updateMember({ data: _.omit(values, ['email']) });

		this.props.initializeForm(FORM_MODEL.PROFILE_GENERAL_SETTINGS, {
			name: values.name,
			email: values.email,
			country: values.country,
		});
	};

	onProfilePictureUpload = (file: File) => {
		return this.props.updateMemberAvatar({ asset: file });
	};

	renderAside = () => (
		<>
			<Navigation links={links.main} />
			<div />
			<User />
		</>
	);

	renderHeader = () => null;

	renderAccountAction = (o: AccountAction) => {
		return (
			<button
				key={o.title}
				type="button"
				onClick={o.onClick}
				className={cn(css.authOptionsListItem, o.disabled && css.authOptionsListItemDisabled)}
			>
				{o.label?.text && (
					<Tag className={css.tag} color={o.label.color}>
						<Text size="label" text={o.label.text} />
					</Tag>
				)}
				<Text className={css.name} tag="div" text={o.title} />
			</button>
		);
	};

	renderAccountActions = () => {
		return (
			<div className={css.accountActions}>
				<div className={css.actionsList}>{_.map(this.accountActions.main, this.renderAccountAction)}</div>
			</div>
		);
	};

	renderContent = () => {
		const { user } = this.props;
		const avatarUrl = getUserAvatarLink(user.member?.profilePictureFile);
		const timeRegistered = dayjs(user.member.createdAt ?? '', 'YYYYMMDD').fromNow();
		const lastSign = dayjs(user.member.lastLogin ?? '').format('DD MMM YYYY, HH:mm');

		return (
			<ContentLayout className={css.contentLayout}>
				<div className={css.contentHeaderWrap}>
					<div className={css.contentHeader}>
						<UploadAvatar
							avatar={avatarUrl}
							onUpload={this.onProfilePictureUpload}
							className={css.uploadAvatar}
						/>
						<Text
							className={css.contentHeaderText}
							size="hero"
							tag="h1"
							weight={Text.weight.normal}
							text={user.member.name ?? ''}
						/>
					</div>
				</div>

				<Switch>
					<Route
						path={routes.PROFILE_2FA_PAGE}
						render={() => {
							return (
								<>
									<Text
										className={css.heading}
										weight={Text.weight.semibold}
										size={Text.size.subtitle}
									>
										{t('profile.2fa.title')}
									</Text>
									{this.twoFAStatus.status && (
										<Alert className={css.authAlert} color={this.twoFAStatus.color}>
											<Text
												size="paragraph"
												text={t(`profile.2fa.2fa_${this.twoFAStatus.status}`)}
											/>
										</Alert>
									)}
									<div className={css.authOptions}>
										<div className={css.authOptionsList}>
											{_.map(this.accountActions.twoFA, this.renderAccountAction)}
										</div>
									</div>
								</>
							);
						}}
					/>
					<Route
						path={routes.PROFILE_PAGE}
						exact
						render={() => {
							return (
								<>
									<div className={css.info}>
										<div className={css.infoRow}>
											<Text tag="p" size="article" weight="medium">
												{t('profile.headerWrap.storiesCreated')} {user.stories?.created ?? ''}
											</Text>
											<Text tag="p" size="article" weight="medium">
												{t('profile.headerWrap.publishedStories')}{' '}
												{user.stories?.published ?? ''}
											</Text>
										</div>
										<div className={css.infoRow}>
											<Text tag="p" size="label">
												{t('profile.headerWrap.registered')} <b>{timeRegistered}</b>
											</Text>
											<Text tag="p" size="label">
												{t('profile.headerWrap.lastSignIn')} <b>{lastSign}</b>
											</Text>
										</div>
									</div>

									<GeneralSettingsForm
										initialValues={{
											name: user.member.name ?? '',
											email: user.member.email ?? '',
											country: user.member.country ?? '',
										}}
										onDeleteAccount={() => this.props.setModal({ id: MODAL.DELETE_USER_ACCOUNT })}
										onSubmit={this.onGeneralSettingsFormSubmit}
										renderAccountActions={() => this.renderAccountActions()}
									/>
								</>
							);
						}}
					/>
				</Switch>
			</ContentLayout>
		);
	};

	render() {
		return (
			<PageLayout
				className={css.profile}
				header={this.renderHeader()}
				aside={this.renderAside()}
				content={this.renderContent()}
			/>
		);
	}
}

export default connector(Profile);
