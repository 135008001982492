import type { UploadVideoParams, UploadVideoResult } from 'src/types';
import { useMutation } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

type Input = UploadVideoParams;
type Output = UploadVideoResult | null;

const uploadVideo = async ({ organizationId, title, extension, parts }: UploadVideoParams) => {
	const response: ApiV2Response<UploadVideoResult> = await videos.upload
		.params({ organizationId })
		.send({ title, extension, parts });

	return response.body;
};

export const useUploadVideo = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);

	return useMutation<Output, ApiV2ResponseError, Omit<Input, 'organizationId'>>({
		mutationFn: params => uploadVideo({ organizationId, ...params }),
	});
};
