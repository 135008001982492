import React, { MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { BBCommonProps, CardData } from 'types/story';
import cn from 'classnames';
import { VariableType } from 'common/utils/variables/types';
import { useCountDown } from 'common/components/useCountDown';
import { injectVariablesValue } from 'common/utils/variables/variables';
import { useClientSelector } from 'client/reducers';
import { selectCard } from 'client/reducers/card/selectors';
import { selectTotalAnswersByCard } from 'client/reducers/user/selectors';
import { CardRendererContext } from 'client/components/common/StoryCard/CardRenderer/context';
import { selectStory, selectStoryCardSettings, selectStorySpreadsheetValuesMap } from 'client/reducers/story/selectors';
import useTruncate from './useTruncate';
import css from './Text.scss';

type DefaultTextEventListeners = BBCommonProps['eventListeners'];

type EditableTextEventListeners = DefaultTextEventListeners & {
	onClick: MouseEventHandler;
	onDoubleClick: MouseEventHandler;
};

type TextProps = {
	nodeProps: BBCommonProps['uiConfig']['nodeProps'];
	stateAttrs: BBCommonProps['stateAttrs'];
	eventListeners: DefaultTextEventListeners | EditableTextEventListeners;
	containerRef: BBCommonProps['containerRef'];
	className: string;
	html: string;
	tag: React.ElementType | string;
	isEditableMode: boolean | undefined;
	maxRows: BBCommonProps['uiConfig']['componentProps']['styles']['lineClamp'];
	maxRowsBtnColor: BBCommonProps['uiConfig']['componentProps']['styles']['lineClampColor'];
};

const TextBody = ({ tag: Tag, isEditableMode, ...props }: TextProps) => {
	const [mounted, setMounted] = useState(false);
	const maxRows = props.maxRows ? parseInt(props.maxRows, 10) : undefined;

	// make extra render to have `props.containerRef.current` initialized to pass to `useTruncate`
	useEffect(() => {
		if (maxRows && !mounted) setMounted(true);
	}, [maxRows, mounted]);

	const __html = useTruncate({
		node: props.containerRef?.current,
		text: props.html,
		maxRows,
		disableEventHandlers: isEditableMode,
		btnClassName: css.truncateBtn,
		containerClassName: css.truncateContainer,
	});

	return (
		<Tag
			{...props.nodeProps}
			{...props.stateAttrs}
			{...props.eventListeners}
			ref={props.containerRef}
			className={cn(props.className, {
				[css.hasMaxRowsColor]: props.maxRows !== undefined && props.maxRowsBtnColor !== undefined,
			})}
			dangerouslySetInnerHTML={{ __html }}
		/>
	);
};

/**
 * Higher-order component that renders the TextBody component with modified HTML by injecting variable values.
 */
const DynamicText = (props: TextProps) => {
	const story = useClientSelector(selectStory)!;
	const spreadsheetValuesMap = useClientSelector(selectStorySpreadsheetValuesMap);
	const cardFromState = useClientSelector(selectCard) as CardData; // current story card
	const cardFromCtx = useContext(CardRendererContext)!; // card that renders current component
	const card = cardFromCtx?.card || /* fallback in case of Text is global */ cardFromState;
	const storyCardSettings = useClientSelector(state => selectStoryCardSettings(state, card._id))!;
	const totalSelectedAnswers = useClientSelector(selectTotalAnswersByCard, (a, b) => {
		// do not update if the text does not contain one of the corresponding variables
		const noMentionOfAnswersSelected = !props.html.includes(`data-mention="${VariableType.answersSelected}"`);
		const noMentionOfAnswersScore = !props.html.includes(`data-mention="${VariableType.answersScore}"`);
		const noMentionOfAnswerIndex = !props.html.includes(`data-mention="${VariableType.answerIndex}"`);
		return a === b || (noMentionOfAnswersSelected && noMentionOfAnswersScore && noMentionOfAnswerIndex);
	});

	// seconds tick, to update time variable. 30 days is just a random long period
	const tick = useCountDown(1000 * 60 * 60 * 24 * 30);
	const tickStart = useRef(tick.start);

	const html = useMemo(() => {
		const result = injectVariablesValue({
			html: props.html,
			story,
			spreadsheetValuesMap,
			card,
			storyCardSettings,
			totalSelectedAnswers,
		});

		if (tick.timeLeft && tick.isPause && result.variables.includes(VariableType.time)) {
			tickStart.current();
		}

		return result.html;
	}, [
		props.html,
		story,
		spreadsheetValuesMap,
		card,
		storyCardSettings,
		totalSelectedAnswers,
		tick.isPause,
		tick.timeLeft,
	]);

	return <TextBody {...props} html={html} />;
};

export default {
	Raw: TextBody,
	WithVariables: DynamicText,
};
