import React from 'react';
import { Alert as AntdAlert, AlertProps as AntdAlertProps } from 'antd';
import cn from 'classnames';

import css from './Alert.scss';

type Props = AntdAlertProps & {
	children?: React.ReactNode;
	color?: 'success' | 'primary' | 'danger' | 'error';
};

const Alert = ({ className, children, color = 'success', ...props }: Props) => {
	return (
		<AntdAlert className={cn(css.alert, css[color], className)} {...props} message={children ?? props.message} />
	);
};

export default Alert;
