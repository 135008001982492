import { createSelector } from 'reselect';
import type { ClientReducerState } from 'client/reducers';
import { StoryFacade } from 'utils/facades/story-facade';
import { selectCardId } from 'client/reducers/card/selectors';

export const selectStoryReducer = (state: ClientReducerState) => state.story;

export const selectStory = createSelector(selectStoryReducer, state => state.story);

export const selectStoryId = createSelector(selectStory, story => story?.id);

export const selectStoryLang = createSelector([selectStory], story => story!.language);

export const selectStorySpreadsheetVariables = createSelector(selectStoryReducer, state => state.spreadsheetVariables);

export const selectStorySpreadsheetValuesMap = createSelector(selectStoryReducer, state => state.spreadsheetValuesMap);

export const selectStoryFacade = createSelector(selectStory, story => new StoryFacade(story!));

export const selectStoryCards = createSelector(selectStoryFacade, facade => facade?.cards);

export const selectStoryElements = createSelector(selectStoryFacade, facade => facade?.elements);

export const selectStorySymbols = createSelector(selectStoryFacade, facade => facade?.symbols);

export const selectIntegrations = createSelector(selectStoryFacade, facade => facade?.settings.integrations);

export const selectFeatureFlags = createSelector(selectStoryReducer, state => state.features);

export const selectStorySettings = createSelector(
	selectStory,
	story => story?.storyVersions?.[StoryFacade.VERSIONS.latest].settings
);

export const selectStoryCardSettings = createSelector(
	[selectStorySettings, (state: ClientReducerState, cardId?: string) => cardId ?? selectCardId(state)],
	(storySettings, cardId) =>
		storySettings !== undefined && cardId !== undefined ? storySettings.cards?.[cardId] : undefined
);

export const selectStorycardsDomain = createSelector(selectStory, story => story?.storycardsDomain);

export const selectCmsItems = createSelector(selectStoryReducer, state => state.cms?.items);

export const selectCmsCollections = createSelector(selectStoryReducer, state => state.cms?.collections);
