import React from 'react';
import { Field } from 'redux-form';

import translate from 'common/utils/translate';
import { TextField } from 'admin/components/common/Form';
import IntegrationsFacade, {
	INTEGRATIONS_PAGE_VIEW,
	INTEGRATIONS_SCOPE,
} from 'common/utils/facades/integrations-facade';
import css from './GoogleAnalytics.scss';

const t = (path: string) => translate(`common.integrations.views.${path}`);

type Props = ({ scope: INTEGRATIONS_SCOPE.STORY; version: string } | { scope: INTEGRATIONS_SCOPE.ORGANIZATION }) & {
	className?: string;
};

export const GoogleAnalytics: React.FC<Props> = props => {
	const fields = IntegrationsFacade.getFields('version' in props ? props.version : undefined).ga;
	const theme = props.scope === INTEGRATIONS_SCOPE.STORY ? 'dark' : 'light';

	return (
		<div className={props.className}>
			<div className={css.sectionField}>
				<Field
					name={fields.ga4.name}
					autoComplete="off"
					component={TextField}
					label={t(`${IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.GOOGLE_ANALYTICS]}.ga4Label`)}
					isLabelUppercase={false}
					placeholder="G-XXXXXXXXXX"
					isRequired
					theme={theme}
				/>
			</div>
		</div>
	);
};
