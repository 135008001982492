import React, { Component } from 'react';
import cn from 'classnames';
import { Icon } from 'admin/components/common/Icon';
import Loader from 'common/components/Loader';
import MenuItem from './MenuItem';
import MenuHint from './MenuHint';

import css from './FullScreenMenu.scss';

const PLACEMENT = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal',
	HORIZONTAL_FROM_RIGHT: 'horizontal_from-right',
} as const;

const SPACING = {
	BASE: 'base',
} as const;

const half = (v: number) => v / 2;

type State = {
	isMenuOpen: boolean;
	menuH: number | null;
	targetX: number;
	targetY: number;
	isBusyIndex: null | number;
};

type Props = {
	className?: string;
	hintBefore?: () => typeof MenuHint | React.ReactNode;
	hintAfter?: () => typeof MenuHint | React.ReactNode;
	spacing?: (typeof SPACING)[keyof typeof SPACING];
	menuClassName?: string;
	labelWrapClassName?: string;
	activeClassName?: string;
	onMenuToggle?: (value: boolean) => void;
	labelBelow?: boolean;
	label?: string | React.ReactNode;
	placement?: (typeof PLACEMENT)[keyof typeof PLACEMENT];
	items: {
		name?: string | React.ReactNode;
		className?: string;
		handler?: (item: any, index: number) => any;
	}[];
	menuRenderer?: (content: React.ReactNode) => React.ReactNode; // helper to render menu in whatever place
};

export default class FullScreenMenu extends Component<Props, State> {
	static Item: typeof MenuItem = MenuItem;

	static Hint = MenuHint;

	static placement = PLACEMENT;

	static spacing = SPACING;

	static defaultProps = {
		placement: PLACEMENT.HORIZONTAL,
	};

	menuRef: React.RefObject<HTMLDivElement> = React.createRef();

	labelRef: React.RefObject<HTMLDivElement> = React.createRef();

	state = {
		isMenuOpen: false,
		menuH: null,
		targetX: 0,
		targetY: 0,
		isBusyIndex: null,
	};

	componentWillUnmount() {
		document.body.style.overflow = '';
		window.removeEventListener('resize', this.updateMenuPosition);
		window.removeEventListener('scroll', this.updateMenuPosition);
	}

	handleLabelClick = () => {
		const onComplete = () => this.props.onMenuToggle && this.props.onMenuToggle(this.state.isMenuOpen);

		this.setState(
			prevState => ({ isMenuOpen: !prevState.isMenuOpen }),
			() => {
				document.body.style.overflow = this.state.isMenuOpen ? 'hidden' : '';

				if (this.state.isMenuOpen) {
					const { x, y, menuH } = this.getMenuPosition();
					this.setState(
						{
							targetX: x,
							targetY: y,
							menuH,
						},
						onComplete
					);
					window.addEventListener('resize', this.updateMenuPosition);
					window.addEventListener('scroll', this.updateMenuPosition);
				} else {
					this.setState(
						{
							targetX: 0,
							targetY: 0,
							menuH: null,
						},
						onComplete
					);
					window.removeEventListener('resize', this.updateMenuPosition);
					window.removeEventListener('scroll', this.updateMenuPosition);
				}
			}
		);
	};

	onItemClick: React.MouseEventHandler<HTMLDivElement> = async e => {
		const { index = '' } = e.currentTarget.dataset;
		const item = this.props.items[index];

		this.setState({ isBusyIndex: +index });

		await item.handler?.(this.props.items[index], parseInt(index, 10));

		this.setState({ isBusyIndex: null });

		this.handleLabelClick();
	};

	getMenuPosition = () => {
		const { placement } = this.props;
		const { targetY, targetX } = this.state;
		const isNearPos = placement === PLACEMENT.HORIZONTAL;

		const { innerWidth: winW, innerHeight: winH } = window;

		const menuEl = this.menuRef.current;
		const labelEl = this.labelRef.current;
		const labelBBox = labelEl?.getBoundingClientRect();
		const menuBBox = menuEl?.getBoundingClientRect();

		const labelBottom = Math.round(labelBBox?.bottom ?? 0);
		const labelRight = Math.round(labelBBox?.right ?? 0);
		const labelH = Math.round(labelBBox?.height ?? 0);
		const labelW = Math.round(labelBBox?.width ?? 0);
		const menuTop = Math.round(menuBBox?.top ?? 0);

		const menuBottom = Math.round(menuBBox?.bottom ?? 0);
		const menuRight = Math.round(menuBBox?.right ?? 0);
		const menuLeft = Math.round(menuBBox?.left ?? 0);
		const menuH = Math.round(menuBBox?.height ?? 0);
		const menuW = Math.round(menuBBox?.width ?? 0);

		const result: { x: number; y: number; menuH: number | null } = {
			x: isNearPos ? labelW + 10 : 0,
			y: isNearPos ? -(half(menuH) + half(labelH)) : 0,
			menuH: null,
		};

		if (targetY) {
			result.y = targetY;
		}

		if (targetX) {
			result.x = targetX;
		}

		switch (placement) {
			case PLACEMENT.VERTICAL: {
				if (labelBottom + menuH > winH) {
					result.y = -labelH - menuH;
				}

				if (menuRight > winW) {
					result.x = -(menuRight - labelRight);
				}

				return result;
			}
			case PLACEMENT.HORIZONTAL:
			case PLACEMENT.HORIZONTAL_FROM_RIGHT: {
				if (menuLeft < 0) {
					result.x = labelW + 10;
				}

				if (placement === PLACEMENT.HORIZONTAL_FROM_RIGHT) {
					result.x = -menuW + labelW + 10 * 2;
				}

				if (menuBottom > winH) {
					result.y = -(menuBottom - winH) + targetY;

					if (menuBottom + result.y < 0) {
						result.menuH = Math.min(menuH > winH ? menuH - winH : menuH, winH);
						result.y += menuH - result.menuH;

						// add space between items and scrollbar
						const paddingRight = 20;
						if (menuEl) menuEl.style.paddingRight = `${paddingRight}px`;
						result.x -= paddingRight;
					}

					if (menuH > winH) {
						result.menuH = winH - 20;
						result.y = (half(result.menuH) + labelH) * -1;
						result.x = labelRight + 20 + menuW > winW ? -(menuW + 20) : labelW + 20;
					}
				}

				if (menuTop < 0) {
					result.y = targetY - menuTop;
				}

				if (menuRight + result.x > winW) {
					result.x = -(menuW + 10 * 2);
					if (placement === PLACEMENT.HORIZONTAL_FROM_RIGHT) {
						result.x = -(menuW + 10 * 2);
					}
				}

				// return to the initial placement if there is space for menu
				if (placement !== PLACEMENT.HORIZONTAL_FROM_RIGHT && labelRight + menuW + 10 < winW) {
					result.x = labelW + 10;
				}

				if (menuTop > 0 && menuBottom < half(winH)) {
					// don't understand why this position change should be applied...
					// result.y = -Math.min(menuTop - targetY, menuH / 2 + labelH / 2);
				}

				if (menuBottom < winH && menuTop > half(winH)) {
					result.y = Math.min(Math.abs(menuBottom - winH) + targetY, -(half(menuH) + half(labelH)));
				}

				// fix overflow top
				if (menuTop + result.y < 0) result.y -= menuTop + result.y;
				// fix overflow bottom
				if (menuTop + (result.menuH ?? 0) + result.y > winH)
					result.y -= menuTop + (result.menuH ?? 0) + result.y - winH;

				return result;
			}

			default:
				return result;
		}
	};

	updateMenuPosition = () => {
		const { x, y, menuH } = this.getMenuPosition();

		this.setState({
			targetX: x,
			targetY: y,
			menuH,
		});
	};

	renderLabel = () => {
		const { isMenuOpen } = this.state;
		const { placement, labelBelow, labelWrapClassName, activeClassName = '' } = this.props;
		const isActive = isMenuOpen && placement === PLACEMENT.HORIZONTAL;

		return (
			<div
				ref={this.labelRef}
				className={cn(
					css.label,
					{
						[css.active]: isActive,
						[activeClassName]: isActive,
						[css.labelBelow]: labelBelow,
					},
					labelWrapClassName
				)}
				onClick={this.handleLabelClick}
			>
				{this.props.label || (
					<button type="button" className={css.defaultLabel}>
						<Icon className={css.defaultLabelIcon} type="more" width={20} color="rgba(0, 0, 0, 0.55)" />
					</button>
				)}
			</div>
		);
	};

	renderItem = (item: Props['items'][number], index: number) => {
		const { isBusyIndex } = this.state;
		const isBusy = isBusyIndex === +index;

		return (
			<div
				key={`menuItem-${index}`}
				data-index={index}
				className={cn(css.menuItem, item.className, {
					[css.disabled]: isBusyIndex !== null,
					[css.busy]: isBusy,
				})}
				onClick={this.onItemClick}
			>
				{item.name}
				{isBusy ? (
					<span style={{ margin: '0 16px' }}>
						<Loader bgColor="var(--ra-color-white-mid)" color="var(--ra-color-black-mid)" size="small" />
					</span>
				) : null}
			</div>
		);
	};

	renderMenu = () => {
		const { spacing } = this.props;
		const menuClassNames = cn(css.menu, this.props.menuClassName, { [css[`spacing-${spacing}`]]: spacing });
		const transform = `translate(${this.state.targetX}px, ${this.state.targetY}px)`;
		const height = this.state.menuH ? `${this.state.menuH}px` : 'auto';

		const menu = (
			<div className={menuClassNames} style={{ transform, height }} ref={this.menuRef}>
				<>
					{this.props.hintBefore ? this.props.hintBefore() : null}
					{this.props.items.map(this.renderItem)}
					{this.props.hintAfter ? this.props.hintAfter() : null}
				</>
			</div>
		);

		return (
			<>
				<div className={css.bgMenu} onClick={this.handleLabelClick} />
				{this.props.menuRenderer ? this.props.menuRenderer(menu) : menu}
			</>
		);
	};

	render() {
		const { className, placement } = this.props;
		const { targetX, isMenuOpen } = this.state;
		let orientation = targetX >= 0 ? 'right' : 'left';

		if (placement === PLACEMENT.HORIZONTAL_FROM_RIGHT) {
			orientation = 'left';
		}

		return (
			<div className={cn(css.withMenu, className)} data-orientation={orientation}>
				{this.renderLabel()}
				{isMenuOpen && this.renderMenu()}
			</div>
		);
	}
}
