import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import Text from 'admin/components/common/Text';
import css from './FullScreenMenuItem.scss';

const PRESETS = { DEFAULT: 'DEFAULT', SMALL: 'SMALL', LARGE: 'LARGE', NONE: 'NONE' } as const;

const defaultProps = {
	error: false,
	className: '',
	preset: 'DEFAULT',
};

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'color'> &
	typeof defaultProps & {
		preset?: (typeof PRESETS)[keyof typeof PRESETS];
		className?: string;
		error?: boolean;
		capitalize?: boolean;
		children: React.ReactNode;
	};

const Item = ({ error, className, preset, capitalize, ...props }: Props) => (
	<Text
		{...props}
		{...Item.preset[preset]}
		className={cn(className, css.item, { [css.itemError]: error, [css.capitalize]: capitalize })}
	/>
);

Item.preset = {
	[PRESETS.DEFAULT]: {
		size: Text.size.heading,
		weight: Text.weight.normal,
		compact: true,
	},
	[PRESETS.SMALL]: {
		size: Text.size.paragraph,
		weight: Text.weight.normal,
		compact: true,
	},
	[PRESETS.LARGE]: {
		size: Text.size.hero,
		weight: Text.weight.normal,
		compact: true,
	},
	[PRESETS.NONE]: {},
};

Item.defaultProps = defaultProps;

export default Item;
