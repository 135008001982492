import React, { FC, useCallback, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { routes } from 'admin/constants';
import { init } from 'admin/actions/init';
import Login from 'admin/components/pages/Login';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import NotFound from 'admin/components/pages/NotFound';
import RouterSwitch from 'admin/components/common/RouterSwitch';
import PrivateRoute from 'admin/components/common/PrivateRoute';
import NetworkStatus from 'admin/components/common/NetworkStatus';
import ForgotPassword from 'admin/components/pages/Login/ForgotPassword';
import TwoFAEnforcement from 'admin/components/pages/Login/TwoFAEnforcement';
import RestorePassword from 'admin/components/pages/Login/RestorePassword';
import { TransitionSwitch } from 'admin/components/common/TransitionSwitch';
import IframeMessageReceiver from 'admin/components/common/IframeMessageReceiver';
import StoryCoEditingObserver from 'admin/components/common/StoryCoEditingObserver';
import BillingWarning from 'admin/components/common/GlobalNotification/BillingWarning';
import Settings from 'admin/components/pages/Settings';
import Reports from 'admin/components/pages/Reports';
import Profile from 'admin/components/pages/Profile';
import { selectUser } from 'admin/reducers/user/selectors';
import ModalManager from 'admin/components/common/ModalManager';
import Intercom from 'admin/components/common/Intercom';
import Loader from 'src/common/components/Loader';
import { IconsPreview } from 'admin/components/common/Icon';
import VideoGalleryObserver from 'admin/components/common/VideoGalleryObserver';

const AppLoader = () => (
	<Loader fullScreen bgColor="var(--ra-color-white-mid)" color="var(--ra-color-black-mid)" size="medium" />
);

const SuperUser = React.lazy(() => import('admin/components/pages/SuperUser'));
const Stories = React.lazy(() => import('admin/components/pages/Stories'));
const Videos = React.lazy(() => import('src/routes/admin/components/pages/Videos'));
const Story = React.lazy(() => import('admin/components/pages/Story'));
const Preview = React.lazy(() => import('admin/components/pages/Preview'));
const AccountSetup = React.lazy(() => import('admin/components/pages/AccountSetup'));
const InviteAccept = React.lazy(() => import('admin/components/pages/InviteAccept'));
const VerifyMember = React.lazy(() => import('admin/components/pages/VerifyMember'));

const App: FC = () => {
	const dispatch = useAdminDispatch();
	const user = useAdminSelector(selectUser);
	const [isInitialized, setIsInitialized] = useState(false);
	const onRouterSwitchUpdate = useCallback(() => {
		window.scrollTo(0, 0);
	}, []);

	const userId = user.id;

	useEffect(() => {
		const startInitialization = async () => {
			await dispatch(init());
			setIsInitialized(true);
		};

		if (!userId && !isInitialized) {
			startInitialization();
		}
	}, [dispatch, userId, isInitialized]);

	if (!isInitialized) {
		// avoid rendering until we at least verified the user
		return <AppLoader />;
	}

	return (
		<>
			{user.verified && !!user.id && <BillingWarning />}

			<ModalManager />
			<NetworkStatus />
			<StoryCoEditingObserver />
			<VideoGalleryObserver />
			<IframeMessageReceiver id="admin" />
			<PrivateRoute
				path={[routes.STORIES_PAGE, routes.SETTINGS_PAGE, routes.REPORTS_PAGE]}
				component={Intercom}
			/>

			<RouterSwitch onUpdate={onRouterSwitchUpdate}>
				<PrivateRoute superuser path={routes.ICONS_PAGE} component={IconsPreview} />
				<PrivateRoute path={routes.STORY_PREVIEW_PAGE} component={Preview} />
				<PrivateRoute path={routes.SETTINGS_PAGE} component={Settings} />
				<Route exact path={routes.LOGIN_PAGE} component={Login} />
				<TransitionSwitch>
					{(status, location) => (
						<React.Suspense fallback={<AppLoader />}>
							<RouterSwitch location={location} onUpdate={onRouterSwitchUpdate}>
								<Route exact path={routes.RESTORE_PASSWORD_PAGE} component={RestorePassword} />
								<Route exact path={routes.RESET_PASSWORD_PAGE} component={ForgotPassword} />
								<Route exact path={routes.TWO_FA_ENFORCEMENT_PAGE} component={TwoFAEnforcement} />
								<Route path={routes.ACCOUNT_SETUP_PAGE} component={AccountSetup} />
								<PrivateRoute path={routes.VERIFY_MEMBER_PAGE} component={VerifyMember} />
								<Redirect exact from={routes.ROOT} to={routes.STORIES_PAGE} />

								<PrivateRoute path={routes.PROFILE_PAGE} component={Profile} />
								<PrivateRoute exact path={routes.STORIES_PAGE} component={Stories} />
								<PrivateRoute path={routes.VIDEOS_PAGE} component={Videos} />
								<PrivateRoute path={routes.REPORTS_PAGE} component={Reports} />
								<PrivateRoute path={routes.STORY_PAGE} component={Story} />
								<PrivateRoute exact path={routes.STORY_FLOW_PAGE} component={Story} />
								<PrivateRoute path={routes.INVITE_ACCEPT_PAGE} component={InviteAccept} />
								<PrivateRoute exact path={routes.STORY_CARD_EDITOR_PAGE} component={Story} />
								<PrivateRoute superuser path={routes.SUPERUSER_ROOT} component={SuperUser} />
								<PrivateRoute exact path={routes.ANY} component={NotFound} />
							</RouterSwitch>
						</React.Suspense>
					)}
				</TransitionSwitch>
			</RouterSwitch>
		</>
	);
};

export default App;
