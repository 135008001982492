import { useCallback, useEffect, useMemo, useState } from 'react';
import PromisePool from '@supercharge/promise-pool';

import { useUploadVideo, useUploadVideoComplete } from 'admin/queries/videos';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { toast } from 'admin/components/common/MessageContainer/toast';

import { useAdminSelector } from '../reducers';
import { selectActiveOrganizationId, selectUserId } from '../reducers/user/selectors';

type Params = {
	controller: AbortController;
};

export type CurrentUpload = {
	uploadId: string;
	uploadKey: string;
	uploaded: Array<{ ETag: string; index: number }>;
	urls: Array<string>;
	fileName: string;
	fileSize: number;
	startTime: number | null;
};

const LS_UPLOAD_INFO_DEFAULT = {
	uploadId: '',
	uploadKey: '',
	uploaded: [],
	urls: [],
	fileSize: 0,
	fileName: '',
	startTime: null,
};
const HOUR = 1000 * 60 * 60;
export const VIDEO_UPLOAD_URL_TTL = 5 * HOUR;

export const useUploadVideoProcess = (params: Params) => {
	const { controller } = params;

	const userId = useAdminSelector(selectUserId);
	const organizationId = useAdminSelector(selectActiveOrganizationId);

	const [isBusy, setIsBusy] = useState(false);
	const [uploadingProgress, setUploadingProgress] = useState(0);
	const [uploadDataInLS, setUploadDataInLS, removeItemFromLS] = useLocalStorage<CurrentUpload>(
		`current-upload-${userId}-${organizationId}`,
		LS_UPLOAD_INFO_DEFAULT
	);

	const { mutateAsync: uploadVideoAsync } = useUploadVideo();
	const { mutateAsync: uploadVideoCompleteAsync } = useUploadVideoComplete();

	const upload = useCallback(
		async (video: File, title: string, onSuccess: () => void) => {
			try {
				setIsBusy(true);

				const arrayBuffer = await convertFileToArrayBuffer(video);
				const chunks = splitBufferIntoChunks(arrayBuffer);
				const ext = video.name.split('.').pop();
				const isSameFileUploading = Boolean(uploadDataInLS.uploadId) && uploadDataInLS.fileSize === video.size;
				let completedRequests = isSameFileUploading ? uploadDataInLS.uploaded.length : 0;
				let uploadMetaData = {
					id: uploadDataInLS.uploadId,
					key: uploadDataInLS.uploadKey,
					urls: uploadDataInLS.urls,
				};

				// case when user upload file for the first time
				// (when localStorage is empty or current fileSize is not equal to prev upload fileSize)
				// other words when user upload another file
				if (!isSameFileUploading) {
					const result = await uploadVideoAsync({
						title,
						extension: `.${ext}`,
						parts: chunks.length,
					});

					if (!result) throw new AdminError('Video upload failed.Please try again');

					setUploadDataInLS({
						uploadId: result?.id || '',
						uploadKey: result?.key || '',
						urls: result?.urls || [],
						fileSize: video.size,
						fileName: video.name,
						uploaded: [],
						startTime: Date.now(),
					});

					uploadMetaData = {
						id: result?.id || '',
						key: result?.key || '',
						urls: result?.urls,
					};
				}

				const { urls } = uploadMetaData;
				const { results: uploadedParts } = await PromisePool.for(urls)
					.withConcurrency(20)
					.useCorrespondingResults()
					.handleError(async error => {
						throw error;
					})
					.process(async (url, index) => {
						const maxRetries = 3;
						const backoff = 400;
						const alreadyUploadedPart = isSameFileUploading
							? uploadDataInLS.uploaded.find(u => u.index === index)
							: undefined;

						const attemptUpload = async (attempt = 0) => {
							try {
								const response = await fetch(url, {
									signal: controller.signal,
									method: 'PUT',
									body: chunks[index],
									headers: {
										'Content-Type': 'application/octet-stream',
									},
								});

								if (!response.ok) {
									throw new Error(`Failed to upload part ${index}: ${response.status}`);
								}

								// calculate progress
								completedRequests += 1;
								setUploadingProgress((completedRequests / urls.length) * 100);

								// save upload part info to localStorage
								setUploadDataInLS(prev => ({
									...prev,
									uploaded: [...prev.uploaded, { ETag: response.headers.get('ETag') || '', index }],
								}));

								return { ETag: response.headers.get('ETag') || '' };
							} catch (error) {
								if (attempt < maxRetries && error !== 'CANCELED_BY_USER') {
									console.info(`Attempt ${attempt + 1} for part ${index} failed:`, error);
									await new Promise(resolve => setTimeout(resolve, backoff * (attempt + 1)));
									return attemptUpload(attempt + 1);
								}

								throw new Error(
									error === 'CANCELED_BY_USER'
										? 'Upload aborted'
										: `Failed to upload part ${index} after ${maxRetries} attempts`
								);
							}
						};

						if (alreadyUploadedPart) {
							return { ETag: alreadyUploadedPart.ETag };
						}

						return attemptUpload();
					});

				removeItemFromLS();

				await uploadVideoCompleteAsync({
					id: uploadMetaData.id,
					key: uploadMetaData.key,
					parts: uploadedParts,
				});

				onSuccess();
				setIsBusy(false);
			} catch (e) {
				setIsBusy(false);
				toast.error((e as Error).message || JSON.stringify(e), 7);
			}
		},
		[
			controller.signal,
			uploadVideoAsync,
			removeItemFromLS,
			setUploadDataInLS,
			uploadVideoCompleteAsync,
			uploadDataInLS,
		]
	);

	useEffect(() => {
		if (uploadDataInLS.uploadId && Date.now() - Number(uploadDataInLS.startTime || 0) > VIDEO_UPLOAD_URL_TTL) {
			removeItemFromLS();
		}
	}, [uploadDataInLS, removeItemFromLS]);

	return { progress: uploadingProgress, isBusy, upload, prevUploadInfo: getUploadInfo(uploadDataInLS) };
};

const getUploadInfo = (uploadDataInLS: CurrentUpload) => {
	if (uploadDataInLS.uploadId && Date.now() - Number(uploadDataInLS.startTime || 0) > VIDEO_UPLOAD_URL_TTL) {
		return LS_UPLOAD_INFO_DEFAULT;
	}

	return uploadDataInLS;
};

export const splitBufferIntoChunks = (buffer: ArrayBuffer): ArrayBuffer[] => {
	const chunks: ArrayBuffer[] = [];
	const chunkSize = 5 * 1024 * 1024; // 5MB chunks
	const totalChunks = Math.ceil(buffer.byteLength / chunkSize);

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < totalChunks; ++i) {
		const start = i * chunkSize;
		const end = Math.min(start + chunkSize, buffer.byteLength);
		const chunk = buffer.slice(start, end); // This is valid for ArrayBuffer
		chunks.push(chunk);
	}

	return chunks;
};

export const convertFileToArrayBuffer = async (file: File): Promise<ArrayBuffer> => {
	return new Promise(resolve => {
		const reader = new FileReader();

		reader.onload = e => {
			resolve(e.target?.result as ArrayBuffer);
		};

		reader.readAsArrayBuffer(file);
	});
};

export function useLocalStorage<T>(key: string, initialValue: T) {
	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			const item = localStorage.getItem(key);

			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(error);
			return initialValue;
		}
	});

	useEffect(() => {
		try {
			localStorage.setItem(key, JSON.stringify(storedValue));
		} catch (error) {
			console.error(error);
		}
	}, [key, storedValue]);

	const removeItem = useCallback(() => {
		setStoredValue(initialValue);
		localStorage.removeItem(key);
	}, [key, initialValue]);

	return useMemo(() => {
		return [storedValue, setStoredValue, removeItem] as const;
	}, [removeItem, storedValue]);
}
