import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { CountType } from 'common/constants';
import translate from 'common/utils/translate';
import Text from 'admin/components/common/Text';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { useDidMount } from 'common/components/useDidMount';
import { Modal, ModalBody } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { CARD_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import { Select, TextField } from 'admin/components/common/Form';
import { Grid, Column } from 'admin/components/common/Grid';
import Button from 'admin/components/common/Button';
import { useAdminSelector } from 'src/routes/admin/reducers';
import { selectEditableStoryForm } from 'src/routes/admin/reducers/story-editor/selectors';
import css from './CreateNavigationModal.scss';

export type CreateNavigationModalData = {
	onSave: (type: CountType, name: string) => Promise<void>;
};

interface FieldsI {
	name: string;
	type: CountType;
}

type Props = ModalManagerProvidedProps<MODAL.CREATE_NAVIGATION_CARD>;

type PropsWithForm = Props & InjectedFormProps<FieldsI, Props>;

const t = (v: string) => translate(`story.createNavigationModal.${v}`);

const showErrorsOn = {
	name: (meta, input) => meta.submitFailed && !meta.active,
};

const OPTIONS = [
	{ value: CountType.score, label: 'By score range' },
	{ value: CountType.character, label: 'By character points' },
	{ value: CountType.ab, label: 'A/B Navigation' },
	{ value: CountType.urlParam, label: 'By URL parameters' },
];

const WIDGET_OPTIONS = OPTIONS.filter(option => [CountType.ab, CountType.urlParam].includes(option.value));

const CreateNavigationModal: React.FC<PropsWithForm> = ({ initialize, initialValues, ...props }) => {
	const story = useAdminSelector(selectEditableStoryForm);
	const storyType = story?.type;
	const isWidget = storyType === 'widget';
	const options = isWidget ? WIDGET_OPTIONS : OPTIONS;

	const onSubmit = (values: FieldsI) => props.data.onSave(values.type, values.name);

	useDidMount(() => {
		if (isWidget) initialize({ ...initialValues, type: WIDGET_OPTIONS[0].value });
	});

	return (
		<Modal
			open={props.open}
			title={
				<Grid columns="12">
					<Column justifyContent="space-between" colSpan="12">
						<span />
						<Text size={Text.size.subheading} weight={Text.weight.normal} compact text={t('title')} />
						<Button
							view="label-only"
							textSize={Text.size.paragraph}
							textWeight={Text.weight.normal}
							size="auto"
							onClick={props.close}
						>
							{t('cancel')}
						</Button>
					</Column>
				</Grid>
			}
			destroyOnClose
			width={1366}
			className={css.modal}
			maskColor="dark"
			transitionName=""
		>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<ModalBody className={css.body}>
					<Field
						name="name"
						component={TextField}
						label={t(`name.label`)}
						limit={{ max: CARD_NAME_MAX_LEN }}
						// autoSelect
						autoFocus
						isRequired
						autoComplete="off"
						placeholder={t(`name.ph`)}
						isLabelUppercase={false}
						showErrorsOn={showErrorsOn.name}
						view={TextField.view.hero}
					/>
					<Field
						name="type"
						component={Select}
						menuPosition="fixed"
						label={{ children: t('typeLabel') }}
						options={options}
						inline
					/>
					<Button
						type="submit"
						view="primary"
						loading={props.submitting}
						disabled={props.submitting}
						smallText
						className={css.submit}
					>
						{t('create')}
					</Button>
				</ModalBody>
			</form>
		</Modal>
	);
};

export default reduxForm<FieldsI, Props>({
	form: FORM_MODEL.CREATE_CARD,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true, // <---- unregister fields on unmount
	initialValues: {
		name: 'Navigation',
		type: CountType.score,
	},
	validate: (values, props) => {
		const errors: Partial<Record<keyof FieldsI, string>> = {};

		if (!values.name) {
			errors.name = t('name.required');
		}

		return errors;
	},
})(CreateNavigationModal);
