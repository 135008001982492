import type { StorySettingsType, BBModel, StorySymbols } from 'types/story';
import cms from 'utils/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalkFull } from 'utils/blocks/component-walk-full';
import { parseComponentProps } from 'utils/blocks/parse-component-props';

export class CmsReferencesManager {
	// Map<collectionId, Set<collectionDataId>
	private collectionRefs = new Map<string, Set<string>>();

	// Map<collectionId, [1, 100, Infinity, ...]>
	private collectionLimits = new Map<string, number[]>();

	public clear() {
		this.collectionRefs.clear();
		this.collectionLimits.clear();
	}

	public getReferences() {
		return Array.from(this.collectionRefs.keys()).map(collectionId => {
			// note: minLimit is not used, until there is no option to fetch only particular items by id
			//       in this case, we can use minLimit to fetch only particular items, like only 2 instead of 100
			// const minLimit = this.collectionRefs.get(collectionId)?.size ?? 0;
			const maxLimit = this.collectionLimits.get(collectionId)?.sort((a, b) => b - a)[0];

			return {
				id: collectionId,
				// limit: maxLimit === Infinity ? null : maxLimit ?? minLimit,
				limit: maxLimit === Infinity ? null : maxLimit ?? cms.defaultProps.collectionLimit,
			};
		});
	}

	collectComponentReferences(elements: BBModel[], symbols: StorySymbols = {}) {
		componentWalkFull({
			elements,
			symbols,
			callback: ({ component }) => {
				parseComponentProps(component, ({ prop, value }) => {
					this.collectPropertyReferences(prop, value, component);
				});
			},
		});
	}

	collectPropertyReferences(property: string, value: unknown, component: BBModel) {
		if (!value || typeof value !== 'string') {
			return;
		}

		if (property === UNIFORM_PROPS.collectionId) {
			const limit = cms.parseCollectionLimit(
				component.uiConfig.componentProps.collectionLimit ?? cms.defaultProps.collectionLimit,
				component.uiConfig.componentProps.collectionPagination ?? cms.defaultProps.collectionPagination
			);

			if (this.collectionLimits.has(value)) {
				this.collectionLimits.get(value)?.push(limit ?? Infinity);
			} else {
				this.collectionLimits.set(value, [limit ?? Infinity]);
			}

			if (!this.collectionRefs.has(value)) {
				this.collectionRefs.set(value, new Set());
				return;
			}
		}

		const ref = cms.parseReference(value, false);

		if (!ref) {
			return;
		}

		if (!this.collectionRefs.has(ref.collectionId)) {
			this.collectionRefs.set(ref.collectionId, new Set());
		}

		this.collectionRefs.get(ref.collectionId)?.add(ref.dataId);
	}

	transformRefsToStorySettingsCollections(): NonNullable<StorySettingsType['collections']> {
		const result: NonNullable<StorySettingsType['collections']> = {};

		const refs = this.getReferences();

		refs.forEach(ref => {
			const dataIdsSet = this.collectionRefs.get(ref.id) ?? new Set();

			result[ref.id] = {
				dataIds: Array.from(dataIdsSet).filter(v => v !== cms.DATA_ANY),
				repeatable: dataIdsSet.has(cms.DATA_ANY),
				limit: ref.limit,
			};
		});

		return result;
	}
}
