import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classnames from 'classnames';

import type { AdminReducerState } from 'admin/reducers';
import MemberAvatar from 'admin/components/common/MemberAvatar';
import { logout, setMemberPreferences, setActiveTeam, getOrganizationDomains } from 'admin/actions';
import { getOrganization } from 'admin/actions/organization/organization';
import { getFeatureFlags } from 'admin/actions/member';

import Usage from './Usage';
import Menu from './Menu';

import css from './User.scss';

type OwnProps = {
	className?: string;
	view?: 'default' | 'only-avatar';
	menuPosition?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
	menuView?: 'full' | 'minimal';
	onChangeOrganization?: (orgId: string) => void;
	skipSetPreferences?: boolean;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

type State = {
	isMenuOpened: boolean;
};

/**
 * Now <Header /> supposed to be self-controlled component
 * (FE: it determines set of links by current route, not relying on passed links prop as before)
 */
class User extends React.PureComponent<Props, State> {
	state: State = {
		isMenuOpened: false,
	};

	menuRef = React.createRef<HTMLDivElement>();

	avatarBtnRef = React.createRef<HTMLButtonElement>();

	onLogOutClick = () => {
		this.props.logout();
	};

	onDocumentClick = (e: Event) => {
		if (
			(this.menuRef.current && this.menuRef.current.contains(e.target as Node)) ||
			(this.avatarBtnRef.current && this.avatarBtnRef.current.contains(e.target as Node))
		) {
			return;
		}

		this.setState({ isMenuOpened: false });
		document.removeEventListener('click', this.onDocumentClick);
	};

	onUserAvatarClick = () => {
		this.setState(
			prvState => ({ isMenuOpened: !prvState.isMenuOpened }),
			() => {
				if (this.state.isMenuOpened) {
					document.addEventListener('click', this.onDocumentClick);
				}
			}
		);
	};

	onOrganizationClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
		const { id: organizationId } = e.currentTarget.dataset;
		const { user, onChangeOrganization } = this.props;
		const activeOrganizationId = user.organization?.id;

		if (!organizationId || organizationId === activeOrganizationId) {
			return;
		}

		this.props.getFeatureFlags({ organizationId });
		this.props.getOrganizationDomains({ organizationId });
		await this.props.getOrganization({ organizationId });
		this.props.setActiveTeam('');

		// At stories page preferences updated by UserScope. FIXME: update from a single place
		if (!this.props.skipSetPreferences) {
			this.props.setMemberPreferences({
				memberId: user.id,
				preferences: {
					last: { organization: organizationId, team: '' },
				},
			});
		}

		onChangeOrganization?.(organizationId);
	};

	render() {
		const { user, view = 'default', menuView = 'full', menuPosition = 'topLeft', className = '' } = this.props;
		const { isMenuOpened } = this.state;

		if (!user.id) {
			return null;
		}

		return (
			<div className={classnames(css.user, css[menuPosition], className)}>
				<button ref={this.avatarBtnRef} type="button" onClick={this.onUserAvatarClick}>
					<MemberAvatar className={css.userAvatar} user={user.member} />
				</button>

				{view === 'default' && <Usage user={this.props.user} />}

				<Menu
					view={menuView}
					ref={this.menuRef}
					isOpen={isMenuOpened}
					user={this.props.user}
					onOrganizationClick={this.onOrganizationClick}
					onLogOutClick={this.onLogOutClick}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: AdminReducerState) => ({
	user: state.user,
});

const mapDispatchToProps = {
	logout,
	setMemberPreferences,
	setActiveTeam,
	getOrganization,
	getOrganizationDomains,
	getFeatureFlags,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(User);
