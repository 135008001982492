import React from 'react';
import { ChangeAction } from 'redux-form';
import type { CardFlowEvent } from 'types/story';
import { SCORE } from 'common/constants';
import { CardEventFactory } from 'common/utils/facades/templates/card-event';
import Button from 'admin/components/common/Button';
import type { RangePropType, ScoreRange } from './ScoreRangeSliders/utils';
import ScoreRangeSliders from './ScoreRangeSliders';

const DEFAULT_SCORE_RANGES = [
	{ _id: 'id-1', min: SCORE.RANGE.MIN, max: 49 },
	{ _id: 'id-2', min: 50, max: SCORE.RANGE.MAX },
];

const getScoreRanges = (events: CardFlowEvent[]) => {
	return events
		.filter(e => e.score)
		.reduce<ScoreRange[]>((memo, value) => {
			memo.push({
				_id: value._id,
				min: value.score![0],
				max: value.score![1],
				original: { ...value },
			});
			return memo;
		}, []);
};

type ScoreEventsProps = {
	events: CardFlowEvent[];
	change: ChangeAction;
};

const ScoreEvents = ({ events, change }: ScoreEventsProps) => {
	const onUpdate = React.useCallback(
		(nextScoreRanges: (RangePropType & { original?: CardFlowEvent })[]) => {
			const scoreRangeEvents = nextScoreRanges.reduce<CardFlowEvent[]>((memo, value) => {
				const eventData = {
					score: [+value.min, +value.max],
					name: `Score ${value.min}${value.max === value.min ? '' : `-${value.max}`}`,
				};

				memo.push(
					value.original
						? { ...value.original, ...eventData }
						: CardEventFactory.create('nav-score', eventData)
				);

				return memo;
			}, []);
			// update form field
			change('events', [...events.filter(e => !e.score), ...scoreRangeEvents]);
		},
		[change, events]
	);

	const scoreRanges = getScoreRanges(events);

	if (scoreRanges.length === 0) {
		return (
			<Button type="button" theme="dark" view="label-only" onClick={() => onUpdate(DEFAULT_SCORE_RANGES)}>
				Create score events
			</Button>
		);
	}

	return <ScoreRangeSliders scoreRanges={scoreRanges} onUpdate={onUpdate} />;
};

export default ScoreEvents;
