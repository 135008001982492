import React from 'react';
import cn from 'classnames';

import css from './Tag.scss';

export type Props = {
	children?: React.ReactNode;
	className?: string;
	text?: string;
	color?: 'success' | 'primary' | 'danger' | 'error';
};

const Tag = ({ children, className, text, color = 'success' }: Props) => (
	<div className={cn(css.tag, css[color], className)}>
		{children ?? (text && <span className={css.text}>{text}</span>)}
	</div>
);

export default Tag;
