import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import _ from 'lodash';

import { FORM_MODEL } from 'admin/constants/common';
import t from 'utils/translate';
import { isFullName, isRequired } from 'admin/components/common/Form/validations';

export type Values = { name: string; email: string; country: string };

const defaultProps = {
	className: '',
	children: _.noop,
	onSubmit: _.noop,
};

type Props = {
	className?: string;
	children?: (...args: any[]) => any;
	onSubmit?: (...args: any[]) => any;
} & typeof defaultProps;

const Form = (props: Props & InjectedFormProps<Values, Props>) => {
	return (
		<form className={props.className} onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children(props)}
		</form>
	);
};

Form.defaultProps = defaultProps;

export default reduxForm<Values, Props>({
	validate: ({ name = '', country = '' }) => {
		const errors: Partial<Values> = {};
		const _t = (label: string) => t(`profile.generalSettingsForm.fields.${label}`);

		if (!isRequired(name)) {
			_.set(errors, 'name', _t('name.errors.required'));
		} else if (!isFullName(name)) {
			_.set(errors, 'name', t('accountSetup.fields.name.fullName'));
		}

		if (!isRequired(country)) {
			_.set(errors, 'country', _t('country.errors.required'));
		}

		return errors;
	},
	destroyOnUnmount: true,
	form: FORM_MODEL.PROFILE_GENERAL_SETTINGS,
})(Form);
