import { BBModel, StorySymbols, CollectionPaginationType } from 'types/story';
import type { CmsCollectionDataItem } from 'types/cms';
import type { CmsChangePageEvent } from 'common/utils/cms/cms-event-emitter';
import cms from 'common/utils/cms';
import { detachSymbol } from 'common/utils/blocks/get-symbol-model';

type HandlePaginationProps = {
	element: BBModel;
	collectionId: string;
	collectionData: CmsCollectionDataItem[];
	symbols: StorySymbols;
	baseComponentId: CmsChangePageEvent['data']['baseComponentId'] | undefined;
	page: CmsChangePageEvent['data']['page'] | undefined;
};

type PaginationType = {
	page: number;
	pageSize: number;
	originalElement: BBModel;
	duplicatesCount: number;
	// page - classic pagination | infinite - infinite scroll
	strategy: 'page' | 'infinite';
};

type InitPaginationStateProps = {
	element: BBModel;
	collectionId: string;
	pageSize: number;
	symbols: StorySymbols;
};

type UpdatePaginationPageProps = {
	page: CmsChangePageEvent['data']['page'];
	totalItems: number;
};

class PaginationManager {
	// An object that stores pagination information for each original(base) component
	private paginationState: { [collectionId: string]: { [originalElementId: string]: PaginationType } } = {};

	// A Map that stores the origin of elements by mapping element IDs to their original element IDs.
	originMap = new Map</* current component id */ string, /* original component id */ string>();

	getPagination({ collectionId, originalElementId }: { collectionId: string; originalElementId: string }) {
		return this.paginationState[collectionId]?.[originalElementId];
	}

	handlePagination(props: HandlePaginationProps) {
		const { element, collectionId, collectionData, symbols, baseComponentId, page } = props;
		let pagination = this.getPagination({ collectionId, originalElementId: this.originMap.get(element._id) ?? '' });

		const {
			collectionPageSize = cms.defaultProps.collectionPageSize,
			collectionPagination = cms.defaultProps.collectionPagination,
		} = element.uiConfig.componentProps;

		const isPaginated = collectionPageSize > 0 && pagination !== undefined;

		if (!pagination) {
			const collectionLimit = cms.parseCollectionLimit(
				element.uiConfig.componentProps.collectionLimit,
				collectionPagination
			);

			const pageSize =
				collectionPagination === CollectionPaginationType.none
					? collectionLimit ?? cms.defaultProps.collectionLimit
					: collectionPageSize;

			pagination = this.initializePaginationState({ element, collectionId, pageSize, symbols });
		} else if (isPaginated && baseComponentId === pagination.originalElement._id && page !== undefined) {
			try {
				this.updatePaginationPage(pagination, { page, totalItems: collectionData.length });
			} catch (e) {
				return { dataSlice: [], deleteCount: 0, pagination };
			}
		} else {
			return { dataSlice: [], deleteCount: 0, pagination };
		}

		const dataSlice = this.getDataSlice(collectionData, pagination);
		const deleteCount = isPaginated ? pagination.duplicatesCount : 1;
		pagination.duplicatesCount = dataSlice?.length ?? 0;

		return { dataSlice, deleteCount, pagination };
	}

	private initializePaginationState(props: InitPaginationStateProps) {
		const { element: originalElement } = detachSymbol(props.element, props.symbols);
		// Why `detachSymbol`?
		// Due to the complexity and lack of real necessity, it was decided to simplify the logic and transform
		// duplicated elements into plain blocks

		this.paginationState[props.collectionId] = {
			...this.paginationState[props.collectionId],
			[originalElement._id]: {
				page: 1,
				pageSize: props.pageSize ?? cms.defaultProps.collectionPageSize,
				duplicatesCount: 0,
				originalElement,
				strategy: 'page',
			},
		};

		return this.getPagination({ collectionId: props.collectionId, originalElementId: originalElement._id });
	}

	private updatePaginationPage(draftPagination: PaginationType, props: UpdatePaginationPageProps) {
		const pageMax = Math.ceil(props.totalItems / draftPagination.pageSize);
		let nextPage: number;

		switch (props.page) {
			case 'next':
				nextPage = draftPagination.page + 1;
				break;
			case 'prev':
				nextPage = draftPagination.page - 1;
				break;
			default:
				nextPage = props.page;
				break;
		}

		if (nextPage < 1 || nextPage > pageMax || Number.isNaN(nextPage)) {
			throw new Error('Invalid page number');
		}

		draftPagination.page = nextPage;
	}

	private getDataSlice(collectionData: CmsCollectionDataItem[], pagination: PaginationType) {
		return collectionData?.slice(
			pagination.pageSize * (pagination.page - 1),
			pagination.pageSize * pagination.page
		);
	}
}

export default new PaginationManager();
