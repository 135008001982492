import type { CardData, CardTemplate } from 'types/story';
import { CardEventFactory } from 'utils/facades/templates/card-event';
import FloatAboveModel from 'client/components/common/BuildingBlocks/FloatAbove/model';
import FloatBelowModel from 'client/components/common/BuildingBlocks/FloatBelow/model';
import ContentModel from 'client/components/common/BuildingBlocks/Content/model';
import CardModel from 'client/components/common/BuildingBlocks/Card/model';
import { generateCardId } from 'utils/generate-id';

interface Props {
	type: CardData['type'];
	name: string;
}

export function defaultTemplate({ type, name }: Props): CardTemplate {
	const cardId = generateCardId();
	return {
		id: '',
		name: 'Blank page',
		subtitle: 'Blank template.',
		description: 'Blank template to build your card from scratch.',
		organizationId: null,
		editor: {
			card: {
				_id: cardId,
				type,
				name,

				elements: [
					FloatAboveModel(),
					ContentModel(),
					FloatBelowModel(),
					// @ts-expect-error ts-migrate FIXME: change styles & other to WithStateAndPlatform<DataType>
					CardModel(),
				],
				settings: {},
				events: [CardEventFactory.create('default', {})],
			},
		},
		metadata: {
			storySettings: {
				cards: {
					[cardId]: {},
				},
			},
			storyId: '',
			cardId: '',
			clientStoryId: '',
			version: process.env.VERSION as string,
			defaultPlatform: 'default',
		},
		type: 'card',
		language: '',
		categories: [],
	};
}
