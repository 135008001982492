import React from 'react';
import cn from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';
import Text from 'admin/components/common/Text';
import css from './NavLink.scss';

type Props = {
	className?: string;
	activeClassName?: string;
	to: string;
	text?: string | React.ReactElement;
	textProps?: {};
	children?: React.ReactElement;
	noActiveState?: boolean;
	type?: 'internal' | 'external';
};

const NavLink = ({ textProps = {}, noActiveState = false, type = 'internal', ...props }: Props) => {
	const className = cn(css.link, props.className);
	const activeClassName = noActiveState ? '' : cn(css.active, props.activeClassName);
	const content = props.text ? (
		<Text text={props.text as string} size={Text.size.label} weight={Text.weight.bold} {...textProps} />
	) : (
		props.children
	);

	return type === 'internal' ? (
		<RouterNavLink to={props.to} className={className} activeClassName={activeClassName}>
			{content}
		</RouterNavLink>
	) : (
		<a href={props.to} className={className} target="_blank" rel="noreferrer">
			{content}
		</a>
	);
};

export { NavLink };
