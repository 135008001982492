import type { CardFlowEvent, CardFlowEventType } from 'types/story';
import { generateCardEventId } from 'utils/generate-id';
import { CONNECTION_TYPES, FLOW_DEFAULT_EVENT_NAME } from 'common/constants';

interface BaseEventProps {
	name?: string;
	pointId?: string;
	connectionType?: CardFlowEventType;
}

interface DefaultEventProps extends BaseEventProps {}

interface ComponentEventProps extends BaseEventProps {
	componentId: string;
}

interface NavScoreEventProps extends BaseEventProps {
	score: number[];
}

interface NavAbEventProps extends BaseEventProps {
	rate: number;
	isDefault?: true;
}

interface NavCharacterEventProps extends BaseEventProps {}

interface NavUrlEventProps extends BaseEventProps {
	isDefault?: true;
}

type CardEventPropsMap = {
	default: DefaultEventProps;
	component: ComponentEventProps;
	'nav-score': NavScoreEventProps;
	'nav-ab': NavAbEventProps;
	'nav-character': NavCharacterEventProps;
	'nav-url': NavUrlEventProps;
};

// Type guard for event-specific props
type EventType = 'default' | 'component' | 'nav-score' | 'nav-ab' | 'nav-character' | 'nav-url';

export class CardEventFactory {
	/**
	 * Creates a CardFlowEvent of the specified type
	 */
	static create<T extends EventType>(type: T, props: CardEventPropsMap[T]): CardFlowEvent {
		const baseEvent: CardFlowEvent = {
			_id: generateCardEventId(),
			name: props.name || FLOW_DEFAULT_EVENT_NAME,
			connection: { type: props.connectionType || CONNECTION_TYPES.NEXT },
		};

		// Set connection details if pointId is provided
		if (props.pointId) {
			baseEvent.connection!.type = props.connectionType || CONNECTION_TYPES.ANY;
			baseEvent.connection!.pointId = props.pointId;
		}

		switch (type) {
			case 'default':
				return this.createDefaultEvent(baseEvent);
			case 'component':
				return this.createComponentEvent(baseEvent, props as ComponentEventProps);
			case 'nav-score':
				return this.createNavScoreEvent(baseEvent, props as NavScoreEventProps);
			case 'nav-ab':
				return this.createNavAbEvent(baseEvent, props as NavAbEventProps);
			case 'nav-character':
				return this.createNavCharacterEvent(baseEvent);
			case 'nav-url':
				return this.createNavUrlEvent(baseEvent, props as NavUrlEventProps);
			default:
				throw new Error(`Unknown event type: ${type}`);
		}
	}

	private static createDefaultEvent(baseEvent: CardFlowEvent): CardFlowEvent {
		return { ...baseEvent, isDefault: true };
	}

	private static createComponentEvent(baseEvent: CardFlowEvent, { componentId }: ComponentEventProps): CardFlowEvent {
		return { ...baseEvent, componentId };
	}

	private static createNavScoreEvent(baseEvent: CardFlowEvent, { score }: NavScoreEventProps): CardFlowEvent {
		return { ...baseEvent, score };
	}

	private static createNavAbEvent(baseEvent: CardFlowEvent, { rate, isDefault }: NavAbEventProps): CardFlowEvent {
		return { ...baseEvent, rate, ...(isDefault ? { isDefault } : null) };
	}

	private static createNavCharacterEvent(baseEvent: CardFlowEvent): CardFlowEvent {
		return baseEvent;
	}

	private static createNavUrlEvent(baseEvent: CardFlowEvent, { isDefault }: NavUrlEventProps): CardFlowEvent {
		return { ...baseEvent, ...(isDefault ? { isDefault } : null) };
	}
}
