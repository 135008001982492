import React from 'react';

import type { BBModel } from 'types/story';
import { Pin } from 'common/constants';
import { convertPxToVw, int } from 'common/utils/helpers';

type ChildrenData = {
	[id: string]: {
		id: string;
		pinType: Pin;
		prev?: {
			elementBBoxG: Pick<DOMRect, 'top' | 'left' | 'bottom' | 'width' | 'height'>;
			elementBBoxL: Pick<DOMRect, 'top' | 'left' | 'width' | 'height'>;
		};
	};
};

type Props = {
	children: React.ReactNode;
	contentInnerRef: React.RefObject<HTMLDivElement>;
	isEmbedAutoHeight: boolean;
};

const PINS = {
	top: [Pin.lt, Pin.t, Pin.rt, Pin.none] as string[],
	CENTER: [Pin.l, Pin.c, Pin.r] as string[],
	bottom: [Pin.lb, Pin.b, Pin.rb] as string[],
};

export const AUTO_HEIGHT_ATTR = {
	name: 'data-auto-height',
	value: {
		none: undefined, // default
		preserve: 'preserve', // preserve bbox of some element for do not affect on content height (use prev bbox)
	},
} as const;

/**
 * @description React component, which purpose is to calculate size and position of Content BB first level children,
 * to determine, which height have to be assigned to Content BB, to fit all these children. In other words,
 * Content BB height depends on 1st level children size and position.
 *
 * @link https://storycards.atlassian.net/l/cp/VnZi1Ra2
 */
export default class AutoHeight extends React.Component<Props> {
	framesCounter = 0;

	rafID = 0;

	childrenData: ChildrenData = {};

	creditSpace = 0;

	componentDidMount() {
		window.requestAnimationFrame(this.onRAF);
	}

	componentWillUnmount() {
		window.cancelAnimationFrame(this.rafID);
	}

	getCreditSpace() {
		if (this.creditSpace) return this.creditSpace;
		const content = this.props.contentInnerRef.current;
		return content?.parentElement ? int(getComputedStyle(content.parentElement).paddingBottom) : 0;
	}

	getChildrenData = () => {
		const { children } = this.props;
		const result: ChildrenData = {};

		if (children === null || children === undefined) {
			return result;
		}

		this.childrenData = React.Children.map(children as React.ReactElement<BBModel>, child => {
			if (!React.isValidElement(child)) {
				return child;
			}
			return {
				id: child.props.uiConfig.nodeProps.id,
				pinType: child.props.uiConfig.componentProps.other?.pinType ?? Pin.none,
			};
		}).reduce((acc, current) => {
			acc[current.id] = current;
			acc[current.id].prev = this.childrenData?.[current.id]?.prev;
			return acc;
		}, result);

		return this.childrenData;
	};

	onRAF = () => {
		this.framesCounter += 1;
		this.rafID = window.requestAnimationFrame(this.onRAF);
		if (this.framesCounter % 3 !== 0) return;
		// request every 3rd frame only
		this.resizeContent();
	};

	getChildBBox = (element: Element, data: ChildrenData[string]) => {
		const isPreserve = element.getAttribute(AUTO_HEIGHT_ATTR.name) === AUTO_HEIGHT_ATTR.value.preserve;
		const prevValues = data.prev;
		const styles = window.getComputedStyle(element);
		let elementBBoxG; // global, relative to window
		let elementBBoxL; // local, relative to parent element

		if (isPreserve && prevValues) {
			// Use prev values instead of current. Used usually to discard resize when some element is being animated.
			elementBBoxG = { ...prevValues.elementBBoxG };
			elementBBoxL = { ...prevValues.elementBBoxL };
		} else {
			elementBBoxG = element.getBoundingClientRect();
			elementBBoxL = {
				top: (parseFloat(styles.top) || 0) + (parseFloat(styles.marginTop) || 0),
				left: (parseFloat(styles.left) || 0) + (parseFloat(styles.marginLeft) || 0),
				width: parseFloat(styles.width) || 0,
				height: parseFloat(styles.height) || 0,
			};
		}

		return { elementBBoxG, elementBBoxL, alignSelf: styles['align-self'] };
	};

	getContentHeight = () => {
		const contentInnerRef = this.props.contentInnerRef.current;
		const childrenData = this.getChildrenData();
		const sizeList = [0];

		[...(contentInnerRef?.children ?? [])].forEach(child => {
			if (!childrenData[child.id] || child.clientHeight === 0) {
				return;
			}

			const { pinType } = childrenData[child.id];
			const { elementBBoxG, elementBBoxL, alignSelf } = this.getChildBBox(child, childrenData[child.id]);

			childrenData[child.id].prev = { elementBBoxG, elementBBoxL };

			// skip auto-stretched items (stretched vertically):
			if (alignSelf === 'stretch') {
				return;
			}

			if (PINS.top.includes(pinType)) {
				sizeList.push(elementBBoxL.top + elementBBoxL.height);
			} else if (PINS.CENTER.includes(pinType)) {
				sizeList.push((Math.abs(elementBBoxL.top) + elementBBoxL.height / 2) * 2);
			} else if (PINS.bottom.includes(pinType)) {
				sizeList.push(elementBBoxL.top * -1 + elementBBoxL.height);
			}
		});

		return Math.max(...sizeList);
	};

	resizeContent = () => {
		const { isEmbedAutoHeight } = this.props;
		const contentHeight = !isEmbedAutoHeight
			? this.getContentHeight() || window.innerHeight - this.getCreditSpace()
			: this.getContentHeight();

		if (this.props.contentInnerRef.current) {
			this.props.contentInnerRef.current.style.height = convertPxToVw(contentHeight);
		}
	};

	render() {
		return this.props.children;
	}
}
