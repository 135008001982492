import type { DeleteVideoParams, DeleteVideoResult } from 'src/types/videos';
import { useMutation } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

const deleteVideoQueryFn = async ({ organizationId, videoId }: DeleteVideoParams) => {
	await videos.delete.params({ organizationId, videoId }).send();
	return { videoId };
};

export const useDeleteVideo = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	return useMutation<DeleteVideoResult, ApiV2ResponseError, Pick<DeleteVideoParams, 'videoId'>>({
		mutationFn: ({ videoId }) => deleteVideoQueryFn({ organizationId, videoId }),
	});
};
