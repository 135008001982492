export const FORM_MODEL = {
	LOGIN: 'login',
	RESET_PASSWORD: 'resetPassword',
	RESTORE_PASSWORD: 'restorePassword',
	CREATE_STORY: 'createStory',
	CREATE_VIDEO: 'createVideo',
	EDIT_VIDEO: 'editVideo',
	EDIT_STORY: 'storyEditor',
	EMBED_SETTINGS: 'embedSettings',
	CREATE_CARD: 'cardCreate',
	EDIT_CARD: 'cardEditor',
	EDIT_CARD_SETTINGS: 'cardSettings',
	CREATE_SYMBOL: 'createSymbol',
	SEARCH_MEMBER: 'searchMember',
	GENERAL_SETTINGS: 'generalSettings',
	INVITE_SETUP: 'inviteSetup',
	PROFILE_GENERAL_SETTINGS: 'profileGeneralSettings',
	CHANGE_USER_EMAIL: 'changeUserEmail',
	CHANGE_USER_PASSWORD: 'changeUserPassword',
	PAYMENT_DATA: 'paymentData',
	WORKSPACE_SETUP: 'workspaceSetup',
	WORKSPACE_SETTINGS: 'workspaceSettings',
	CONTACT_DATA: 'contactData',
	RENAME_TEAM: 'renameTeam',
	ORGANIZATION_INTEGRATIONS: 'orgIntegrations',
	TWO_FA_CODE: 'twoFaCode',
	TWO_FA_REMOVAL: 'twoFaRemoval',
} as const;

export enum MODAL {
	EDITOR_AI = 'EDITOR_AI',
	SWITCH_TEAM = 'SWITCH_TEAM',
	MOVE_STORY = 'MOVE_STORY',
	CREATE_STORY = 'CREATE_STORY',
	CREATE_VIDEO = 'CREATE_VIDEO',
	DELETE_STORY = 'DELETE_STORY',
	VIDEO_ACTION = 'VIDEO_ACTION',
	STORY_SETTINGS = 'STORY_SETTINGS',
	CARD_SETTINGS = 'CARD_SETTINGS',
	STORY_PUBLISH = 'STORY_PUBLISH',
	CREATE_CARD = 'CREATE_CARD',
	EDIT_CARD = 'EDIT_CARD',
	DELETE_CARD = 'DELETE_CARD',
	CREATE_NAVIGATION_CARD = 'CREATE_NAVIGATION_CARD',
	DELETE_TEAM = 'DELETE_TEAM',
	RENAME_TEAM = 'RENAME_TEAM',
	ADD_FONTS = 'ADD_FONTS',
	REMOVE_MEMBER_FROM_WORKSPACE = 'REMOVE_MEMBER_FROM_WORKSPACE',
	UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER',
	DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT',
	CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL',
	CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
	INFORMATION = 'INFORMATION',
	STORY_EDITORS_CONFLICT = 'STORY_EDITORS_CONFLICT',
	KICK_OUT_INFO_MODAL = 'KICK_OUT_INFO_MODAL',
	PROP_SETTINGS = 'PROP_SETTINGS',
	EDIT_INTEGRATION = 'EDIT_INTEGRATION',
	EDIT_SELECT_OPTIONS = 'EDIT_SELECT_OPTIONS',
	EDIT_OVERLAY_TRIGGERS = 'EDIT_OVERLAY_TRIGGERS',
	EDIT_CMS_PAGINATION_TRIGGERS = 'EDIT_CMS_PAGINATION_TRIGGERS',
	EDIT_SORTABLE_BOX_ORDER = 'EDIT_SORTABLE_BOX_ORDER',
	TWO_FA = 'TWO_FA',
	TWO_FA_BACKUP_CODES = 'TWO_FA_BACKUP_CODES',
	TWO_FA_REMOVAL = 'TWO_FA_REMOVAL',
	WEBHOOK_LOGS = 'WEBHOOK_LOGS',
	MEDIA_GALLERY = 'MEDIA_GALLERY',
	CMS_PROP_BIND_MODAL = 'CMS_PROP_BIND_MODAL',
	NONE = '',
}

export const LANG_OPTIONS = [
	{ value: 'EN', label: 'English' },
	{ value: 'HE', label: 'Hebrew' },
];

export enum TwoFAEnforcementOption {
	NotRequired = 'notRequired',
	Required = 'required',
}

export const TWO_FA_ENFORCEMENT_OPTIONS = [
	{ value: TwoFAEnforcementOption.NotRequired, label: 'Do Not Require 2FA' },
	{ value: TwoFAEnforcementOption.Required, label: 'Require 2FA for All Users' },
];

export const INPUT_MASK = {
	CARD: 'card',
	EXP_DATE: 'exp-date',
	NUMBER: 'number',
	WS_NAME: 'ws-name',
} as const;

export const LOCAL_STORAGE_BUFFER_ITEM = 'sc-buffer';

export const STORIES_TEXT_PATH = 'stories';
export const VIDEOS_TEXT_PATH = 'videos';

export const MANAGERS_TEAM_ID = 'managers';

export const SYNC_STATUS = {
	DRAFT: 'DRAFT',
	SYNC: 'SYNC',
	FAIL: 'FAIL',
	UPDATED: 'UPDATED',
} as const;

export const AUTO_SYNC_TARGET = {
	CARD_EDITOR: 'CARD_EDITOR',
	STORY_EDITOR: 'STORY_EDITOR',
} as const;

export const STORY_REPORT_DATA_INTERVALS = {
	DAILY: 'daily',
	WEEKLY: 'weekly',
	MONTHLY: 'monthly',
};

export const DATE_SELECTOR_RANGE = {
	week: {
		value: 'week',
		interval: STORY_REPORT_DATA_INTERVALS.DAILY,
		count: 7,
		unit: 'day',
	},
	month: {
		value: 'month',
		interval: STORY_REPORT_DATA_INTERVALS.DAILY,
		count: 1,
		unit: 'month',
	},
	month3: {
		value: 'month3',
		interval: STORY_REPORT_DATA_INTERVALS.MONTHLY,
		count: 3,
		unit: 'month',
	},
	month6: {
		value: 'month6',
		interval: STORY_REPORT_DATA_INTERVALS.MONTHLY,
		count: 6,
		unit: 'month',
	},
	month12: {
		value: 'month12',
		interval: STORY_REPORT_DATA_INTERVALS.MONTHLY,
		count: 1,
		unit: 'year',
	},
} as const;

export const STORIES_FILTER = {
	status: {
		all: 'all',
		draft: 'draft',
		published: 'published',
	},
	createdBy: {
		me: 'me',
		team: 'team',
	},
	orderBy: {
		lastCreated: 'lastCreated',
		lastEdited: 'lastEdited',
	},
	grouped: 'grouped',
};

export const STORIES_FILTER_ORDER_BY = {
	lastCreated: ['createdAt'],
	lastEdited: ['updatedAt'],
};

export const STORIES_FILTER_DEFAULT = {
	status: STORIES_FILTER.status.all,
	createdBy: STORIES_FILTER.createdBy.team,
	orderBy: STORIES_FILTER_ORDER_BY[STORIES_FILTER.orderBy.lastEdited].join(','),
};

export const STORIES_OFFSET = 0;
export const VIDEOS_OFFSET = 0;

export const STORIES_OFFSET_STEP = 10;
export const PAGINATE_VIDEOS_PAGE_SIZE = 10;

export const INSPECTOR_ID = 'editor-inspector';

export const CARD_EDITOR_ID = 'card-editor';

export const TEAM_MEMBER_ROLES = {
	ALL: 'all',
	STORY_MANAGE_OWN: 'story.manage_own',
	STORY_MANAGE_OTHERS: 'story.manage_others',
	STORY_DELETE: 'story.delete',
	STORY_DESIGN: 'story.designer',
	ANALYTICS: 'story.analytics',
	PUBLISH: 'story.publish',
	MANAGER_MEMBERS: 'manage_members',
};

export const TEMPLATE_CATEGORIES = {
	INTENTION: {
		COLLECT_DATA: 'Collect data',
		COLLECT_LEADS: 'Collect leads',
		INCREASE_TIME_ON_PAGE: 'Increase time on page',
		ENGAGE_USERS: 'Engage users',
		CREATE_SEGMENTS: 'Create segments',
		MARKETING_MESSAGES: 'Communicating marketing messages',
		SUMMARY_BY_SCORE: 'Summary by score',
		SAY_SOMETHING: 'Say something',
	},
};

export const PLANS_NAMES = {
	STARTER: 'Starter',
	PROFESSIONAL: 'Professional',
	BUSINESS: 'Business',
	ENTERPRISE: 'Enterprise',
};

export const PLANS_PRIORITY = [
	PLANS_NAMES.ENTERPRISE,
	PLANS_NAMES.BUSINESS,
	PLANS_NAMES.PROFESSIONAL,
	PLANS_NAMES.STARTER,
];

export const PLANS_OPTIONS = {
	[PLANS_NAMES.STARTER]: [
		'10k page views per month',
		'1 Seat',
		'1 Team',
		'Create up to 10 stories',
		'Variety of engagement products',
		'Storycards Editor',
		'Build multiple flows',
		'Custom URL',
		'Lead generation system',
		'Scoring system',
		'Design by platform and devices',
		'SEO tools',
		'Templates gallery',
		'Rich animation',
		'Embed anywhere',
		'Real Time Analytics dashboard',
		'Export user data',
		'Unlimited revision history',
	],
	[PLANS_NAMES.PROFESSIONAL]: [
		'Everything in Starter+',
		'100K page views per month',
		'3 teams',
		'10 seats',
		'Unlimited stories',
		'Create your organization',
		'Advanced roles and permissions',
		'24/5 Email Support',
		'99.9% uptime',
	],
	[PLANS_NAMES.BUSINESS]: [
		'Everything in Professional+',
		'500K page views',
		'10 teams',
		'30 seats',
		'Custom CSS',
		'Marketing pixels',
		'24/5 Email Support',
		'99.9% uptime',
	],
	[PLANS_NAMES.ENTERPRISE]: [
		'Everything in Business+',
		'Unlimited page views',
		'Unlimited teams',
		'Unlimited seats',
		'Custom fonts',
		'Add your own code and scripts',
		'Google Analytics Integration',
		'Dedicated 3rd-party Integration',
		'24x7 email and phone support',
		'Response time SLA',
		'Dedicated account manager',
		'Advanced production services',
		'Virtual training with a professional storycards designer',
		'99.9% uptime',
	],
};

// ordered hsl color palette for charts and various design purposes
export const SYSTEM_HSL_COLORS = [
	'hsl(162, 63%, 50%)',
	'hsl(205, 41%, 63%)',
	'hsl(33, 67%, 65%)',
	'hsl(291, 61%, 67%)',
	'hsl(0, 0%, 40%)',
	'hsl(0, 0%, 87%)',
	'hsl(215, 100%, 65%)',
	'hsl(346, 100%, 65%)',
	'hsl(291, 61%, 67%)',
	'hsl(3, 68%, 48%)',
	'hsl(48, 89%, 56%)',
	'hsl(248, 53%, 45%)',
	'hsl(169, 100%, 25%)',
	'hsl(196, 9%, 47%)',
	'hsl(334, 61%, 47%)',
	'hsl(204, 98%, 66%)',
	'hsl(220, 56%, 41%)',
	'hsl(187, 100%, 39%)',
];

// List to static data selectors to be used for Intercom guided tours, styles, etc.
export const DATA_SELECTOR = {
	ICON_SELECT: 'icons',
	ICON_SELECT_ACTIVE: 'active-icon',
	ICON_SELECT_INACTIVE: 'inactive-icon',
	FORM_ERROR: 'error',
	PROP_FIELD: (name: string) => `pf-${name}`,
	BLOCK_SIZE: 'block-size',
};
