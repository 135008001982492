import { set, sum, random } from 'lodash';
import produce from 'immer';
import React, { useContext, useMemo } from 'react';
import type { BBCommonProps, StoryModel } from 'types/story';
import { CARD_TYPE } from 'common/constants';
import { location } from 'common/utils/url-helper';
import { CardFacade, StoryFacade } from 'common/utils/facades';
import { ClientReducerState, useClientSelector } from 'client/reducers';
import { CardRendererContext } from 'client/components/common/StoryCard/CardRenderer/context';
import { selectStory, selectStoryCardSettings } from 'client/reducers/story/selectors';
import { selectTotalAnswersByCard, selectUserAnswersByCard } from 'client/reducers/user/selectors';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { EventProviderContext } from 'client/components/pages/Story/EventProvider/Context';
import DefaultAnswer from './DefaultAnswer';
import PollMultipleAnswer from './PollMultipleAnswer';
import SortableAnswer from './SortableAnswer';

const { isPreview } = location.client;

interface GetAnswersDataProps {
	answers: ClientReducerState['user']['answers'][string];
	cardId: string;
	showResults: boolean;
	answerId: string;
}

const answersDataState: {
	previewVotes: Record<string, Record<string, number>>;
	setPreviewVotes: (answerId: string, cardId: string) => void;
	get: (props: GetAnswersDataProps) => GetAnswersDataProps['answers'];
} = {
	previewVotes: {},
	setPreviewVotes(answerId: string, cardId: string) {
		if (this.previewVotes[cardId][answerId] !== -1) {
			return;
		}

		const votesAll = Object.values(this.previewVotes[cardId]);
		const votes = votesAll.filter(v => v !== -1);
		const isLast = votesAll.length - votes.length === 1;
		const total = sum(votes);
		const rest = 100 - total;

		this.previewVotes[cardId][answerId] = isLast ? rest : random(0, rest);
	},
	get(props) {
		return produce(props.answers, draft => {
			// Provide fake votes result to props
			if (isPreview && props.showResults) {
				if (this.previewVotes[props.cardId]?.[props.answerId] === undefined) {
					set(this.previewVotes, [props.cardId, props.answerId], -1);
				}

				if (draft && draft.selected.length > 0) {
					this.setPreviewVotes(props.answerId, props.cardId);

					set(draft, 'votes', { ...this.previewVotes[props.cardId] });
				}
			}
		});
	},
};

const Answer: React.FC<BBCommonProps> = props => {
	const answerId = props._id;
	const eventProvider = useContext(EventProviderContext);
	const { card } = useContext(CardRendererContext)!;
	const story = useClientSelector(selectStory) as StoryModel;

	const SF = new StoryFacade(story);
	const CF = new CardFacade(card);
	const showResults = CF.getShowResults(SF.settings);
	const showCorrect = Boolean(CF.getShowCorrect(SF.settings));

	const cardSettings = SF.settings.cards?.[CF.cardId];
	const userAnswers = useClientSelector(state => selectUserAnswersByCard(state, CF.cardId));
	const totalSelectedAnswers = useClientSelector(state => selectTotalAnswersByCard(state, CF.cardId));
	const storySettingsOfCard = useClientSelector(state => selectStoryCardSettings(state, CF.cardId));
	const answerTimeoutProp = props.uiConfig.componentProps.answerTimeout;
	const answersMax = CardFacade.getAnswersMax(CF.type, storySettingsOfCard);
	const isMultipleAnswers = answersMax > 1;
	const isForceSubmitBtn = CF.getForceSubmitButton(SF.settings);

	// timeout in ms
	const answerTimeout = useMemo(() => {
		const timeout = parseFloat(answerTimeoutProp as string);
		const min = showResults && Number.isNaN(timeout) ? 1 : 0;
		return Math.max(min, Number.isNaN(timeout) ? min : timeout) * 1000;
	}, [showResults, answerTimeoutProp]);

	// info about selected answers and user votes
	const answersData = useMemo(
		() =>
			answersDataState.get({
				answers: userAnswers,
				answerId,
				cardId: CF.cardId,
				showResults,
			}),
		[userAnswers, CF.cardId, showResults, answerId]
	);

	const sharedProps = {
		storyId: SF.storyId,
		cardId: CF.cardId,
		showResults,
		showCorrect,
		cardSettings,
		answersData,
		...eventProvider,
		...props,
	};

	if (card.type === CARD_TYPE.SORTABLE_TRIVIA || card.type === CARD_TYPE.SORTABLE_POLL) {
		return <SortableAnswer {...sharedProps}>{props.children}</SortableAnswer>;
	}

	if ((isMultipleAnswers || isForceSubmitBtn) && card.type === CARD_TYPE.POLL) {
		return (
			<PollMultipleAnswer
				{...sharedProps}
				answersMax={answersMax}
				totalSelectedAnswers={totalSelectedAnswers}
				isForceSubmitBtn={isForceSubmitBtn}
			>
				{props.children}
			</PollMultipleAnswer>
		);
	}

	return (
		<DefaultAnswer {...sharedProps} answerTimeout={answerTimeout}>
			{props.children}
		</DefaultAnswer>
	);
};

export default withCardTransitionContext(Answer);
