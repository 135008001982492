import type { GetVideoOriginalFileLinkParams, GetVideoOriginalFileLinkResult } from 'src/types';
import { useQuery } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { QUERY_KEYS } from '../constants';

const MINUTE = 1000 * 60;

const getVideoOriginalFileLink = async ({ organizationId, videoId }: GetVideoOriginalFileLinkParams) => {
	const response: ApiV2Response<GetVideoOriginalFileLinkResult> = await videos.getOriginalFileLink
		.params({ organizationId, videoId })
		.send();

	return response.body;
};

export const useVideoOriginalFileLink = ({ videoId }: Omit<GetVideoOriginalFileLinkParams, 'organizationId'>) => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	return useQuery({
		queryKey: [QUERY_KEYS.VIDEO_ORIGINAL_FILE_LINK, organizationId, videoId],
		queryFn: () => getVideoOriginalFileLink({ organizationId, videoId }),
		enabled: Boolean(videoId),
		staleTime: Infinity,
		gcTime: 120 * MINUTE,
		refetchInterval: 55 * MINUTE,
	});
};
