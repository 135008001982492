import React from 'react';
import ReactDOM from 'react-dom';
import css from './Debugger.scss';

const Debugger = () => {
	const [value, setValue] = React.useState<any>();
	const [side, setSide] = React.useState<'left' | 'right'>('left');

	React.useEffect(() => {
		window.__storyDebug = (_value: any, _side?: typeof side) => {
			setValue(_value);
			if (_side) setSide(_side);
		};
	}, []);

	if (value === undefined) return null;

	return ReactDOM.createPortal(
		<div className={css.debugger} style={side === 'left' ? { left: 0 } : { right: 0 }}>
			<div className={css.content}>{JSON.stringify(value, null, 3)}</div>
			<div>
				<button type="button" onClick={() => setValue(undefined)}>
					hide
				</button>
				&nbsp;
				<button type="button" onClick={() => setSide(s => (s === 'left' ? 'right' : 'left'))}>
					align
				</button>
			</div>
		</div>,
		document.body
	);
};

export default Debugger;
