/* eslint-disable no-param-reassign */
import React from 'react';
import { ChangeAction } from 'redux-form';
import produce from 'immer';
import cn from 'classnames';

import type { CardFlowEvent } from 'types/story';
import { CardEventFactory } from 'common/utils/facades/templates/card-event';
import { createTemplateName } from 'admin/utils/create-template-name';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';

import RateEvent from './RateEvent';
import css from './RateEvents.scss';

interface Props {
	className?: string;
	events: CardFlowEvent[];
	change: ChangeAction;
}

const RateEvents: React.FC<Props> = props => {
	const { className, events: initialEvents, change } = props;
	const [events, setEvents] = React.useState(initialEvents);

	const adjustDefaultRate = (evnts: CardFlowEvent[]) => {
		const totalRate = evnts.reduce((acc, e, i) => (i > 0 ? acc + (e.rate ?? 0) : acc), 0);
		const defaultRate = Math.max(0, Math.min(100, 100 - totalRate));

		return produce(evnts, draft => {
			draft[0].rate = defaultRate;
		});
	};

	const onEventChange = (index: number, val: number) => {
		const value = Math.min(100, Math.max(0, val));

		const updatedEvents = produce(events, draft => {
			draft[index].rate = value;

			const affectedTracks = draft.filter((e, i) => i !== 0 && i !== index && (e.rate ?? 0) > 0).reverse();
			const affectedTracksSum = affectedTracks.reduce((acc, event, i) => acc + (event.rate ?? 0), 0);
			let extraRate = Math.max(0, affectedTracksSum + value - 100);
			let i = 0;

			while (extraRate > 0) {
				const rate = affectedTracks[i].rate ?? 0;
				if (rate > 0) {
					affectedTracks[i].rate = rate - 1;
					extraRate -= 1;
				} else {
					i += 1;
				}
			}
		});

		const nextEvents = adjustDefaultRate(updatedEvents);
		setEvents(nextEvents);
		change('events', nextEvents);
	};

	const onEventRemove = (index: number) => {
		const updatedEvents = events.filter((_, i) => i !== index);
		const nextEvents = adjustDefaultRate(updatedEvents);

		setEvents(nextEvents);
		change('events', nextEvents);
	};

	const onAddBtnClick = () => {
		const newEvent = CardEventFactory.create('nav-ab', {
			name: createTemplateName({ name: 'Track', exclude: events.map(e => e.name) }),
			rate: Math.round((events[0].rate ?? 0) / 2),
		});
		const updatedEvents = [...events, newEvent];
		const nextEvents = adjustDefaultRate(updatedEvents);

		setEvents(nextEvents);
		change('events', nextEvents);
	};

	return (
		<div className={cn(css.rateEvents, className)}>
			<div className={css.events}>
				{events.map((event, index) => (
					<RateEvent
						events={events}
						index={index}
						key={event._id}
						onChange={onEventChange}
						onRemove={onEventRemove}
					/>
				))}
			</div>
			<Button className={css.addBtn} view="secondary-gray" onClick={onAddBtnClick}>
				<div className={css.addBtnLabel}>
					<Icon className={css.addBtnLabelIcon} type="plus-rounded" />
					<span>Add TestTrack</span>
				</div>
			</Button>
		</div>
	);
};

export default RateEvents;
