import type { UploadVideoCompleteParams, UploadVideoCompleteResult } from 'src/types';
import { useMutation } from '@tanstack/react-query';
import { videos } from 'admin/resources';
import { useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';

type Input = UploadVideoCompleteParams;
type Output = UploadVideoCompleteResult;

const uploadVideo = async ({ organizationId, ...queryParams }: Input) => {
	const response: ApiV2Response<Output> = await videos.uploadComplete
		.params({ organizationId, videoUploadCompleteId: queryParams.id })
		.send({ key: queryParams.key, parts: queryParams.parts });

	return response.body;
};

export const useUploadVideoComplete = () => {
	const organizationId = useAdminSelector(selectActiveOrganizationId);

	return useMutation<Output, ApiV2ResponseError, Pick<Input, 'key' | 'id' | 'parts'>>({
		mutationFn: ({ id, key, parts }) => uploadVideo({ organizationId, id, key, parts }),
	});
};
